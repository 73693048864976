import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Accordion from 'components/accordion';
import Icon from 'components/icon';
import DatesMoreLink from './dates-more-link';
import DatesMoreLinkList from './dates-more-link-list';

const themes = {
  small: '-theme-small',
  medium: '-theme-medium'
};

const DatesMore = ({
  accordion,
  title,
  dates,
  theme,
  showMoreDates,
  className,
  refProp,
  style
}) => {
  return (dates && dates.length) || showMoreDates ? (
    <Accordion {...accordion} buttonTextForLabel={title}>
      {({ Button, Collapse, isOpen, toggle }) => (
        <div
          className={cn('dates-more', theme, className, { '-is-open': isOpen })}
          ref={refProp}
          style={style}
        >
          <Button className={cn('dates-more--button', { '-is-open': isOpen })}>
            {title && (
              <>
                <h3 className="dates-more--title">{title}</h3>
                <Icon
                  className={cn('dates-more--icon', { '-is-open': isOpen })}
                  name="arrow-down"
                />
              </>
            )}
          </Button>
          {dates && dates.length && (
            <Collapse className="dates-more--collapse">
              <DatesMoreLinkList
                dates={dates}
                isOpen={isOpen}
                toggleAccordion={toggle}
              />
            </Collapse>
          )}
        </div>
      )}
    </Accordion>
  ) : null;
};

DatesMore.themes = themes;

DatesMore.propTypes = {
  accordion: PropTypes.exact(Accordion.propTypes),
  title: PropTypes.string,
  dates: PropTypes.arrayOf(PropTypes.exact(DatesMoreLink.propTypes)),
  theme: PropTypes.oneOf(Object.values(themes)),
  showMoreDates: PropTypes.bool,
  className: PropTypes.string,
  refProp: PropTypes.shape(typeof Object),
  style: PropTypes.shape(typeof Object)
};

DatesMore.propTypesMeta = 'exclude';

export default DatesMore;
