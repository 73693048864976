import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Link from 'components-old/link';
import Heading from 'components-old/heading';
import Icon from 'components-old/icon';
import DocumentIcon from 'components-old/document-icon';
import Asterix from '../../assets-old/images/asterix.svg';
import cn from 'classnames';

const themes = {
  narrow: '-theme-narrow'
};

const DownloadList = ({
  groups,
  downloadAllText,
  downloadAllUrl,
  theme,
  requiredText
}) => {
  const [highlightedItem, sethighLightedItem] = useState();
  return (
    <div className={cn('download-list', theme)}>
      {groups.map((group, index) => (
        <div className="download-list--group" key={group.heading + index}>
          {group.heading && (
            <Heading className="download-list--heading">
              {group.heading}
            </Heading>
          )}

          <ul>
            {group.items.map(item => (
              <li
                key={item.text}
                onMouseEnter={() => sethighLightedItem(item.text)}
                onFocus={() => sethighLightedItem(item.text)}
                onMouseLeave={() => sethighLightedItem('')}
              >
                <Link className="download-list--item" url={item.url}>
                  <DocumentIcon
                    size={
                      theme === themes.narrow
                        ? DocumentIcon.sizes.small
                        : undefined
                    }
                    iconTheme={item.iconTheme}
                    type={
                      highlightedItem === item.text
                        ? DocumentIcon.types.hover
                        : undefined
                    }
                    isRequired={item.isRequired}
                  />
                  <div className="download-list--item-text">{item.text}</div>
                  <Icon
                    className="download-list--download-icon"
                    name="download-circle"
                  ></Icon>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
      {requiredText && (
        <div className="download-list--required">
          <span
            className="download-list--required-icon"
            style={{ backgroundImage: `url(${Asterix})` }}
          />
          {requiredText}
        </div>
      )}
      <div>
        {downloadAllText && downloadAllUrl && (
          <div className="download-list--download-all">
            <Link
              icon="download-small"
              url={downloadAllUrl}
              text={downloadAllText}
            />
          </div>
        )}
      </div>
    </div>
  );
};

DownloadList.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.exact({
      heading: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.exact({
          text: PropTypes.string,
          iconTheme: DocumentIcon.propTypes.iconTheme,
          url: PropTypes.string,
          isRequired: PropTypes.bool
        })
      )
    })
  ),
  downloadAllText: PropTypes.string,
  downloadAllUrl: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes)),
  requiredText: PropTypes.string
};

DownloadList.defaultProps = {
  groups: []
};

DownloadList.propTypesMeta = 'exclude';

DownloadList.themes = themes;

export default DownloadList;
