import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import RichText from 'components/rich-text';

const themes = {
  noPadding: '-theme-no-padding'
};

const RichTextBlock = ({ text, theme }) => (
  <div className={cn('rich-text-block', theme)}>
    <RichText {...text} />
  </div>
);

RichTextBlock.propTypes = {
  text: PropTypes.exact(RichText.propTypes)
};

RichTextBlock.propTypesMeta = 'exclude';

RichTextBlock.themes = themes;

export default RichTextBlock;
