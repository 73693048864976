import React from 'react';
import PropTypes from 'prop-types';
import propTypeTheme from 'utils/prop-type-theme';
import NumberBlock from 'components/number-block';
import cn from 'classnames';

const themes = {
  twoColumns: '-theme-two-columns',
  threeColumns: '-theme-three-columns'
};

const NumberListBlock = ({ ...props }) => (
  <div className={cn('number-list-block', props.theme)}>
    {props.numbers &&
      props.numbers.map(numb => <NumberBlock key={numb.number} {...numb} />)}
  </div>
);

NumberListBlock.propTypes = {
  numbers: PropTypes.arrayOf(PropTypes.exact(NumberBlock.propTypes)),
  theme: PropTypes.oneOf(Object.values(themes))
};

NumberListBlock.propTypesMeta = 'exclude';

NumberListBlock.theme = themes;

export default NumberListBlock;
