import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

const themes = {
  isActive: '-is-active',
  isCompleted: '-is-completed',
  resultIsPublished: '-result-is-published',
  isCanceled: '-is-canceled',
  isPlanned: '-is-planned'
};

const DateCardStatus = ({ theme, centered, text }) => (
  <div
    className={cn('date-card-status', {
      '-is-active': theme === themes.isActive,
      '-result-is-published': theme === themes.resultIsPublished,
      '-is-completed': theme === themes.isCompleted,
      '-is-planned': theme === themes.isPlanned,
      '-align-center': centered
    })}
  >
    {text}
  </div>
);

DateCardStatus.propTypes = {
  theme: PropTypes.oneOf(Object.values(themes)),
  centered: PropTypes.bool,
  text: PropTypes.string
};

DateCardStatus.propTypesMeta = 'exclude';

DateCardStatus.themes = themes;

export default DateCardStatus;
