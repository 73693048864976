import React from 'react';
import PropTypes, { string } from 'prop-types';

import cn from 'classnames';

import Icon from 'components/icon';
import doesContain from 'js/utils/doesContain';

const themes = {
  primary: '-theme-primary',
  secondary: '-theme-secondary',
  signal: '-theme-signal',
  dropdown: '-theme-dropdown',
  large: '-theme-large',
  small: '-theme-small',
  light: '-theme-light',

  //old:
  big: '-theme-big',
  link: '-theme-link',
  linkPrimary: '-theme-link-primary',
  linkSecondary: '-theme-link-secondary',
  outline: '-theme-outline',
  orangeOutline: '-theme-orange-outline',
  medium: '-theme-medium',
  uppercase: '-theme-uppercase',
  white: '-theme-white',
  smallMargin: 'theme-small-margin'
};

// This is exported so that other components can reference it
const baseClassName = 'button';

const Button = ({
  attributes,
  className,
  children,
  disabled,
  id,
  icon,
  iconBeforeChildren,
  onClick,
  text,
  theme,
  title,
  type,
  iconFill,
  onMouseEnter,
  onMouseLeave
}) => {
  if (doesContain(theme, themes.dropdown)) {
    icon = 'arrow-down-wide';
  }

  return (
    <button
      aria-label={title}
      className={cn(baseClassName, className, theme, {
        '-has-children': !!children || !!text,
        '-has-icon-left': iconBeforeChildren,
        '-has-icon-right': icon
      })}
      id={id}
      onClick={onClick}
      disabled={disabled}
      title={title}
      type={type}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...attributes}
    >
      {iconBeforeChildren && <Icon fill={iconFill} name={iconBeforeChildren} />}
      {children}
      {text}
      {icon && <Icon fill={iconFill} name={icon} />}
    </button>
  );
};

Button.propTypes = {
  attributes: PropTypes.shape(typeof Object),
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  icon: PropTypes.string,
  iconBeforeChildren: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
  // eslint-disable-next-line @creuna/prop-types-csharp/all
  theme: PropTypes.oneOfType([
    PropTypes.oneOf(Object.values(themes)),
    PropTypes.arrayOf(PropTypes.oneOf(Object.values(themes)))
  ]),
  title: PropTypes.string,
  type: PropTypes.string,
  iconFill: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func
};

Button.defaultProps = {
  attributes: {},
  onClick: () => {},
  theme: [],
  type: 'button',
  fill: false,
  onMouseEnter: () => {},
  onMouseLeave: () => {}
};

Button.propTypesMeta = 'exclude';

Button.themes = themes;
Button.className = baseClassName;

export default Button;
