import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import OPE from 'js/on-page-editing';

import Link from 'components/link';
import NestedLink from 'components/nested-link';
import doesContain from 'js/utils/doesContain';

const themes = {
  border: '-theme-border',
  portfolio: '-theme-portfolio',
  frontpageLinks: '-theme-frontpage-links'
};

const linkThemes = {
  actionLink: '-theme-action-link',
  extraIndentation: '-theme-extra-indentation',
  large: '-theme-large',
  previous: '-previous',
  next: '-next',
  navigationLink: 'navigation-link',
  navigationLinkNP: 'navigation-link-np',
  wrappedHeadingForPrint: 'wrapped-heading-for-print',

  //OLD:
  black: '-theme-black',
  blue: '-theme-blue',
  bold: '-theme-bold',
  fullWidth: '-theme-full-width',
  inlineIcon: '-theme-inline-icon',
  orangeIcon: '-theme-orange-icon',
  small: '-theme-small',
  tag: '-theme-tag',
  orangeTag: '-theme-orange-tag',
  underline: '-theme-underline',
  white: '-theme-white',
  portfolio: '-theme-portfolio'
};

const LinkList = ({
  className,
  items,
  nestedLinks,
  linkTheme,
  onPageEditing,
  theme,
  withIcon
}) => {
  const isPortfolio = linkTheme.indexOf(Link.themes.portfolio) !== -1;
  return (
    <ul
      className={cn('link-list', className, theme, {
        'link-list--with-icon': withIcon && !isPortfolio
      })}
      {...OPE(onPageEditing.items)}
    >
      {nestedLinks &&
        nestedLinks.map((nestedLink, index) => (
          <NestedLink
            key={nestedLink.url + String(index)}
            {...{ item: nestedLink, linkTheme }}
          />
        ))}
      {items &&
        items.map((link, index) => (
          <li
            className={cn('link-list--link', {
              '-theme-large': doesContain(linkTheme, Link.themes.large)
            })}
            key={link.url + String(index)}
          >
            <Link theme={linkTheme} {...link} />
          </li>
        ))}
    </ul>
  );
};

LinkList.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  nestedLinks: PropTypes.arrayOf(NestedLink.propTypes.item),
  // eslint-disable-next-line @creuna/prop-types-csharp/all
  linkTheme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  onPageEditing: PropTypes.exact({
    items: PropTypes.string
  }),
  theme: PropTypes.string,
  withIcon: PropTypes.bool
};

LinkList.propTypesMeta = 'exclude';

LinkList.defaultProps = {
  items: [],
  onPageEditing: {},
  linkTheme: []
};

LinkList.themes = themes;

export default LinkList;
