import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Heading from 'components/heading';
import RichText from 'components/rich-text';

// NOTE: headingLevelOffset is intended to be used by the backend only, as a way to describe the semantic relationships between blocks without having to know what the actual heading levels are.
const RichTextWithTitle = ({
  headingLevel,
  headingLevelOffset,
  htmlId,
  text,
  title
}) => (
  <div className={'rich-text-with-title'}>
    {title && (
      <div data-epi-type="title">
        <Heading
          id={htmlId}
          level={headingLevel}
          levelOffset={headingLevelOffset}
        >
          {title}
        </Heading>
      </div>
    )}
    <div data-epi-type="content">
      <RichText {...text} />
    </div>
  </div>
);

RichTextWithTitle.propTypes = {
  headingLevel: PropTypes.number,
  headingLevelOffset: PropTypes.number,
  htmlId: PropTypes.string,
  text: PropTypes.exact(RichText.propTypes),
  title: PropTypes.string
};

RichTextWithTitle.propTypesMeta = 'exclude';

export default RichTextWithTitle;
