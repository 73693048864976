import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import OPE from 'js/on-page-editing';
import ContentContainer from 'components-old/content-container';
import Accordion from 'components-old/accordion';
import Icon from 'components-old/icon/icon';
import Record from './record';

const TimelineVertical = ({
  title,
  records,
  numberOfVisibleRecords,
  accordion,
  onPageEditing = {},
  isBlock = true
}) => {
  const visibleRecords = records.slice(0, numberOfVisibleRecords);
  const hiddenRecords = records.slice(numberOfVisibleRecords);

  const isExpandable = numberOfVisibleRecords < records.length;
  const isLast = (i, arr) => i === arr.length - 1;

  const ContentContainerOrDiv = isBlock ? 'div' : ContentContainer;
  return (
    <ContentContainerOrDiv className="timeline-vertical">
      {title && (
        <h2 className="timeline-vertical--title" {...OPE(onPageEditing.title)}>
          {title}
        </h2>
      )}

      <Accordion {...accordion} showButtonText={false}>
        {({ Button, Collapse, isOpen }) => (
          <>
            <div {...OPE(onPageEditing.records)}>
              {visibleRecords.map((record, index) => (
                <Record
                  key={`${record.date}-${index}`}
                  {...record}
                  shouldFade={
                    !isOpen && isExpandable && isLast(index, visibleRecords)
                  }
                  hideLine={
                    isLast(index, visibleRecords) && !hiddenRecords.length
                  }
                />
              ))}

              <Collapse>
                <div>
                  {hiddenRecords.map((record, index) => (
                    <Record
                      key={`${record.date}-${index}`}
                      {...record}
                      hideLine={isLast(index, hiddenRecords)}
                    />
                  ))}
                </div>
              </Collapse>
            </div>

            {isExpandable && (
              <Button className="timeline-vertical--expand-button">
                <Icon
                  className={cn('timeline-vertical--icon', {
                    '-is-open': isOpen
                  })}
                  name="small-arrow-down"
                />
              </Button>
            )}
          </>
        )}
      </Accordion>
    </ContentContainerOrDiv>
  );
};

TimelineVertical.propTypes = {
  title: PropTypes.string,
  records: PropTypes.arrayOf(PropTypes.exact(Record.propTypes)),
  numberOfVisibleRecords: PropTypes.number,
  accordion: PropTypes.exact(Accordion.propTypes),
  onPageEditing: PropTypes.exact({
    title: PropTypes.string,
    records: PropTypes.string
  }),
  isBlock: PropTypes.bool
};

TimelineVertical.propTypesMeta = 'exclude';

export default TimelineVertical;
