import React from 'react';
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const CrmEmbedBlock = ({ src }) => {
  return <div dangerouslySetInnerHTML={{ __html: src }}></div>;
};

CrmEmbedBlock.propTypes = {
  src: PropTypes.string.isRequired
};

CrmEmbedBlock.propTypesMeta = 'exclude';

export default CrmEmbedBlock;
