import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import OPE from 'js/on-page-editing';

import Link from 'components-old/link';
import NestedLink from 'components-old/nested-link';

const themes = {
  border: '-theme-border',
  portfolio: '-theme-portfolio',
  frontpageLinks: '-theme-frontpage-links'
};

// imported from Forskningsradet.Frontend/source/components-old/link/link.jsx
const linkThemes = {
  black: '-theme-black',
  bold: '-theme-bold',
  fullWidth: '-theme-full-width',
  inlineIcon: '-theme-inline-icon',
  orangeIcon: '-theme-orange-icon',
  small: '-theme-small',
  tag: '-theme-tag',
  orangeTag: '-theme-orange-tag',
  underline: '-theme-underline',
  white: '-theme-white',
  portfolio: '-theme-portfolio'
};

const LinkList = ({
  className,
  items,
  nestedLinks,
  linkTheme,
  onPageEditing,
  theme,
  withIcon
}) => {
  const iconData = withIcon
    ? {
        iconBeforeChildren: 'small-arrow-right',
        theme: ['-theme-orange-icon', '-theme-inline-icon'].concat(linkTheme),
        iconClassName: 'link-arrow'
      }
    : {};
  const isPortfolio = linkTheme.indexOf(Link.themes.portfolio) !== -1;
  return (
    <ul
      className={cn('link-list', className, theme, {
        'link-list--with-icon': withIcon && !isPortfolio
      })}
      {...OPE(onPageEditing.items)}
    >
      {nestedLinks &&
        nestedLinks.map((nestedLink, index) => (
          <NestedLink
            key={nestedLink.url + String(index)}
            {...{ item: nestedLink, iconData, linkTheme }}
          />
        ))}
      {items &&
        items.map((link, index) => (
          <li key={link.url + String(index)}>
            <Link theme={linkTheme} {...iconData} {...link} />
          </li>
        ))}
    </ul>
  );
};

LinkList.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  nestedLinks: PropTypes.arrayOf(NestedLink.propTypes.item),
  linkTheme: PropTypes.oneOf(Object.values(linkThemes)),
  onPageEditing: PropTypes.exact({
    items: PropTypes.string
  }),
  theme: PropTypes.oneOf(Object.values(themes)),
  withIcon: PropTypes.bool
};

LinkList.propTypesMeta = 'exclude';

LinkList.defaultProps = {
  items: [],
  onPageEditing: {},
  linkTheme: []
};

LinkList.themes = themes;

export default LinkList;
