import React, { useState, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Image from 'components-old/image';
import RandomShapes from 'components-old/nysgjerrigper/quotes-block/random-shapes';

const QuotesBlock = ({
  title,
  quotes,
  blockIndex,
  stepIndex,
  stepsContentNode
}) => {
  if (!quotes || !quotes.length) return null;

  const [shouldAnimateIn, setShouldAnimateIn] = useState(
    quotes.map(() => false)
  );
  const [parallaxScroll, setParallaxScroll] = useState(
    [...Array(quotes.length - 1)].map(() => 0)
  );

  const quoteRefs = quotes.map(() => useRef());
  const randomShapesRefs = [...Array(quotes.length - 1)].map(() => useRef());

  useLayoutEffect(() => {
    if (!stepsContentNode) return;

    const topPosition = ref =>
      ref.current.getBoundingClientRect().top - window.innerHeight;

    const onScroll = () => {
      const quotePositions = quoteRefs.map(ref => topPosition(ref));
      setShouldAnimateIn(state => {
        const nextState = quotePositions.map(nodePos => nodePos < 0);

        const needsToUpdate = nextState.some(
          (shouldAnimateIn, i) => shouldAnimateIn !== state[i]
        );

        if (needsToUpdate) return nextState;
        return state;
      });

      const randomShapePositions = randomShapesRefs.map(ref =>
        topPosition(ref)
      );
      setParallaxScroll(state => {
        const nextState = randomShapePositions.map((nodePos, i) => {
          if (nodePos >= 0) return state[i];
          const scrollAmountWhileInView = -nodePos;
          const speed = 0.5;

          return -(scrollAmountWhileInView * speed);
        });

        const needsToUpdate = nextState.some(
          (parallaxScroll, i) => parallaxScroll !== state[i]
        );

        if (needsToUpdate) return nextState;

        return state;
      });
    };

    stepsContentNode.addEventListener('scroll', onScroll);
    return () => stepsContentNode.removeEventListener('scroll', onScroll);
  }, [stepsContentNode]);

  return (
    <div className="quotes-block">
      {title && <h2 className="quotes-block--title">{title}</h2>}
      {quotes &&
        quotes.map(({ image, text, quotee, addQuoteDash }, index) => (
          <div className="quotes-block--quote" key={quotee}>
            <div
              className={cn('quotes-block--content', {
                '-animate-in': shouldAnimateIn[index]
              })}
              ref={quoteRefs[index]}
            >
              {image && <Image className="quotes-block--image" {...image} />}
              <div className="quotes-block--texts">
                <p className="quotes-block--paragraph">{text}</p>
                {quotee && (
                  <div>{addQuoteDash ? '–' + quotee : '' + quotee}</div>
                )}
              </div>
            </div>
            {index < quotes.length - 1 && (
              <RandomShapes
                ref={randomShapesRefs[index]}
                index={index}
                blockIndex={blockIndex}
                stepIndex={stepIndex}
                style={{ transform: `translateY(${parallaxScroll[index]}px)` }}
              />
            )}
          </div>
        ))}
    </div>
  );
};

QuotesBlock.propTypes = {
  title: PropTypes.string,
  quotes: PropTypes.arrayOf(
    PropTypes.exact({
      image: PropTypes.exact(Image.propTypes),
      quotee: PropTypes.string,
      text: PropTypes.string,
      addQuoteDash: PropTypes.bool
    })
  ),
  blockIndex: PropTypes.number,
  stepIndex: PropTypes.number,
  stepsContentNode: PropTypes.node
};

QuotesBlock.propTypesMeta = 'exclude';

export default QuotesBlock;
