import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import supportedBlocks from './supported-blocks';
import BlockOPE from 'js/block-on-page-editing';
import { error } from 'js/log';

import ReactComponent from 'components/react-component';

const sizes = {
  fullScreen: '-size-full-screen',
  half: '-size-half',
  third: '-size-third',
  quarter: '-size-quarter',
  twoThirds: '-size-two-thirds'
};

const themes = {
  noMargin: '-theme-no-margin',
  largeMargin: '-theme-large-margin'
};

const ContentAreaItem = ({
  additionalComponentProps,
  blockNotSupportedText,
  componentName,
  componentData,
  enableElementSizing,
  isStaticLayout,
  id,
  onPageEditing,
  size,
  theme
}) => {
  const Component = supportedBlocks()[componentName];

  if (!Component) {
    error(`😵 ContentArea does not support rendering of '${componentName}'.`);
    return blockNotSupportedText ? (
      <div className="content-area-item--fallback">
        {blockNotSupportedText} <i>{componentName}</i>
      </div>
    ) : null;
  }

  if (!componentData || !Object.keys(componentData).length) {
    return null;
  }

  const additionalProps = additionalComponentProps[componentName];

  const componentToRender = (
    <Component key={id} {...componentData} {...additionalProps} />
  );

  if (process.env.NODE_ENV !== 'production') {
    // NOTE: wrapping in 'props' in order to do strict validation with 'exact' (top level propTypes are loosely validated)
    PropTypes.checkPropTypes(
      { props: PropTypes.exact(Component.propTypes) },
      { props: { ...componentData, ...additionalProps } },
      'prop',
      componentName
    );
  }

  return (
    <div
      className={cn('content-area-item', enableElementSizing && size, theme, {
        '-sizing-enabled': enableElementSizing,
        '-static-layout': isStaticLayout
      })}
      key={id}
      {...BlockOPE(onPageEditing.contentName, onPageEditing.contentId)}
    >
      {componentToRender}
    </div>
  );
};

ContentAreaItem.propTypes = {
  additionalComponentProps: PropTypes.shape(typeof Object),
  blockNotSupportedText: PropTypes.string,
  componentName: PropTypes.string.isRequired,
  componentData: PropTypes.shape(ReactComponent.propTypes).isRequired, // Intentional use of 'shape' because validation of 'componentData' is handled by the components themselves
  enableElementSizing: PropTypes.bool,
  id: PropTypes.string.isRequired,
  isStaticLayout: PropTypes.bool,
  onPageEditing: PropTypes.exact({
    contentName: PropTypes.string,
    contentId: PropTypes.string
  }),
  size: PropTypes.oneOf(Object.values(sizes)),
  theme: PropTypes.oneOf(Object.values(themes))
};

ContentAreaItem.propTypesMeta = 'exclude';

ContentAreaItem.defaultProps = {
  blockNotSupportedText: null,
  enableElementSizing: true,
  onPageEditing: {},
  additionalComponentProps: {}
};

ContentAreaItem.sizes = sizes;
ContentAreaItem.themes = themes;

export default ContentAreaItem;
