import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import RichText from 'components-old/rich-text';

const Record = ({ date, richText, shouldFade, hideLine }) => (
  <div className="timeline-vertical--record">
    <div className="timeline-vertical--record-date">{date}</div>
    <div className="timeline-vertical--record-timeline">
      <div className="timeline-vertical--record-timeline-marker"></div>
      <div
        className={cn('timeline-vertical--record-timeline-line', {
          '-fade-bottom': shouldFade,
          '-hide': hideLine
        })}
      />
    </div>
    <RichText className="timeline-vertical--record-text" {...richText} />
  </div>
);

Record.propTypes = {
  date: PropTypes.string,
  richText: PropTypes.exact(RichText.propTypes),
  shouldFade: PropTypes.bool,
  hideLine: PropTypes.bool
};

Record.propTypesMeta = 'exclude';

export default Record;
