import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import Button from 'components/button/button';
import Collapse from 'components/collapse';
import HtmlString from 'components/html-string';
import IconWarning from 'components/icon-warning';
import Heading from 'components/heading';
import Icon from 'components/icon';
import ContentContainer from 'components/content-container';

const MINIMUM_HEIGHT = 54;

const MessageGlobal = ({
  id,
  title,
  theme,
  message,
  labels = {},
  index,
  length,
  className,
  setActiveIndex,
  activeIndex,
  setIsOpen,
  isOpen
}) => {
  const [isMultiLined, setIsMultiLined] = useState(undefined);
  const [isMounted, setIsMounted] = useState(false);
  const [isExpandable, setIsExpandable] = useState(
    (!!message && !!message.text) || length > 1
  );
  const [wasPreviouslyActive, setWasPreviouslyActive] = useState({
    wasActive: undefined,
    index: undefined
  });
  const [fade, setFade] = useState(false);
  const [messageHeight, setMessageHeight] = useState();
  const titleRef = useRef();
  const messageGlobalRef = useRef();
  const isActive = activeIndex === index;

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    // When animating to first message - lower height of message to make sure multiline-titled-messages dissapear behind the first message
    if (!isMounted) return;
    const shouldAnimateToFirstMessage = !isOpen && isActive && index !== 0;
    if (shouldAnimateToFirstMessage) {
      window.requestAnimationFrame(() => {
        messageGlobalRef.current.style.height =
          messageGlobalRef.current.offsetHeight + 'px';

        window.requestAnimationFrame(() => {
          messageGlobalRef.current.style.height = MINIMUM_HEIGHT + 'px';
        });
      });
    } else if (isOpen && messageGlobalRef.current.style.height) {
      messageGlobalRef.current.style.height = '';
    }
  }, [isOpen]);

  useEffect(() => {
    if (!titleRef.current) return;
    const multiLined = titleRef.current.offsetHeight > 32;
    if (multiLined) {
      setIsMultiLined(multiLined);
      setIsExpandable(true);
    }
  }, []);

  useEffect(() => {
    const becameInactive = wasPreviouslyActive.wasActive && !isActive;
    if (isMounted) {
      if (becameInactive) {
        setFade('out');
      }
      const becameActive = !wasPreviouslyActive.wasActive && isActive;
      if (becameActive) {
        const isNext = (wasPreviouslyActive.index + 1) % length === index; // "% length" to handle last -> first
        // "&& isOpen" - Always animate backwards after closing
        if (isNext && isOpen) {
          setFade('in-next');
        } else {
          setFade('in-previous');
        }
      }
      (becameActive || becameInactive) && setTimeout(() => setFade(''), 700);
    }

    setWasPreviouslyActive({ wasActive: isActive, index: activeIndex });
    if (!isMounted) setIsMounted(true);
  }, [activeIndex]);

  const computeStyle = () => {
    const width = 100 / length;
    const moveLeft = width * Math.min(index, 1);
    const initialOpacity = !fade && (isActive ? '1' : '0');
    const initialVisibility = !fade && (isActive ? 'visible' : 'hidden');

    return {
      width: `${width}%`,
      marginLeft: `-${moveLeft}%`,
      opacity: initialOpacity,
      visibility: initialVisibility,
      zIndex: isActive && 11
    };
  };
  return (
    <div
      className={cn('message-global--container', theme, {
        '-is-open': isOpen,
        '-fade-out': fade === 'out' && isOpen,
        '-fade-in-next': fade === 'in-next',
        '-fade-in-previous': fade === 'in-previous' && isOpen,
        '-fade-in-previous-slow': fade === 'in-previous' && !isOpen
      })}
      ref={messageGlobalRef}
      style={computeStyle()}
    >
      <ContentContainer className={cn('message-global', className, {})}>
        <Button
          onClick={toggle}
          className={cn('message-global--open-button')}
          attributes={{
            'aria-label': isOpen ? labels.hide : labels.show,
            'aria-expanded': isOpen,
            'aria-controls': id
          }}
        >
          <div className="message-global--open-button-title">
            <IconWarning
              className="message-global--icon-warning"
              theme={theme}
            />
            <Heading
              className={cn('message-global--title', {
                '-multi-lined': isMultiLined
              })}
              ref={titleRef}
              level={3}
            >
              {title}
            </Heading>
          </div>
          {isExpandable && (
            <div className="message-global--open-button-label">
              <span>{isOpen ? labels.hide : labels.show}</span>
              <Icon
                className={cn('message-global--icon-accordion', {
                  '-is-open': isOpen
                })}
                name="small-arrow-down"
                fill
              />
            </div>
          )}
        </Button>

        {isExpandable && (
          <Collapse
            className={cn('message-global--collapse', {
              '-use-intrinsic-height': messageHeight || !isOpen
            })}
            isOpen={isOpen}
            onMeasure={h => {
              !messageHeight && isOpen && setMessageHeight(h);
            }}
          >
            <div
              className="message-global--collapse-inner"
              style={{ height: messageHeight || '100%' }}
            >
              {message && <HtmlString {...message} />}
              {length > 1 && (
                <div className="message-global--navigation">
                  <button
                    onClick={() =>
                      setActiveIndex(index === 0 ? length - 1 : index - 1)
                    }
                  >
                    <Icon name="navigation-arrow" />
                    <span>{index + 1}</span>
                  </button>
                  <span className="message-global--navigation-of">
                    {labels.of}
                  </span>

                  <button onClick={() => setActiveIndex((index + 1) % length)}>
                    <span>{length}</span>
                    <Icon name="navigation-arrow" />
                  </button>
                </div>
              )}
            </div>
          </Collapse>
        )}
      </ContentContainer>
    </div>
  );
};

MessageGlobal.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.exact(HtmlString.propTypes),
  labels: PropTypes.exact({
    show: PropTypes.string,
    hide: PropTypes.string,
    of: PropTypes.string
  }),
  title: PropTypes.string,
  theme: IconWarning.propTypes.theme,
  index: PropTypes.number,
  length: PropTypes.number,
  className: PropTypes.string,
  setIsOpen: PropTypes.func,
  setActiveIndex: PropTypes.func,
  activeIndex: PropTypes.number,
  isOpen: PropTypes.bool,
  updateHighestMessage: PropTypes.func,
  highestMessage: PropTypes.number
};

MessageGlobal.propTypesMeta = 'exclude';

export default MessageGlobal;
