import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import propTypeTheme from 'utils/prop-type-theme';

import logoWhite from '../../assets-old/images/logo-white.png';
import logo from '../../assets-old/images/logo.png';

const themes = {
  light: '-theme-light'
};

const SimpleHeader = ({ url, text, theme }) => (
  <div className={cn('simple-header', theme)}>
    <a href={url} className="simple-header--logo">
      <span>{text}</span>
      <img alt="Logo" src={theme === themes.light ? logo : logoWhite} />
    </a>
  </div>
);

SimpleHeader.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes))
};

SimpleHeader.propTypesMeta = 'exclude';

SimpleHeader.themes = themes;

export default SimpleHeader;
