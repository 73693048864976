import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Accordion from 'components/accordion';
import Button from 'components/button';
import RichText from 'components/rich-text';
import Icon from 'components/icon';
import Proposal from './proposal';

const ProposalGroup = ({
  accordion,
  numberOfVisibleItems,
  proposals,
  subtitle,
  text,
  title,
  headerAccordion,
  headingLevel
}) => {
  const visibleItems = proposals.slice(0, numberOfVisibleItems);
  const hiddenItems = proposals.slice(numberOfVisibleItems);

  return (
    <div className="proposal-group">
      <Accordion {...headerAccordion} buttonTextForLabel={title}>
        {({ Collapse, Button, isOpen, toggle }) => (
          <div className="proposal-group--header" onClick={toggle}>
            <Button className="proposal-group--header--button">
              {title && <h2>{title}</h2>}
              <Icon
                className={cn('proposal-group--header--icon', {
                  '-is-open': isOpen
                })}
                name="small-arrow-down"
                fill={true}
              />
            </Button>
            <Collapse>
              <div className="proposal-group--content">
                <p>{subtitle}</p>
                <RichText className="proposal-group--text" {...text} />
              </div>
            </Collapse>
          </div>
        )}
      </Accordion>

      {visibleItems.map(proposal => (
        <Proposal headingLevel={headingLevel} key={proposal.id} {...proposal} />
      ))}

      <Accordion showButtonText={true} {...accordion}>
        {({ Button: AccordionButton, Collapse }) => (
          <React.Fragment>
            <Collapse>
              <div>
                {hiddenItems.map(proposal => (
                  <Proposal
                    headingLevel={headingLevel}
                    key={proposal.id}
                    {...proposal}
                  />
                ))}
              </div>
            </Collapse>

            <AccordionButton
              className="proposal-group--toggle"
              theme={Button.themes.secondary}
            />
          </React.Fragment>
        )}
      </Accordion>
    </div>
  );
};

ProposalGroup.propTypes = {
  accordion: PropTypes.exact(Accordion.propTypes),
  headerAccordion: PropTypes.exact(Accordion.propTypes),
  numberOfVisibleItems: PropTypes.number,
  proposals: PropTypes.arrayOf(PropTypes.exact(Proposal.propTypes)),
  subtitle: PropTypes.string,
  text: PropTypes.exact(RichText.propTypes),
  title: PropTypes.string,
  headingLevel: PropTypes.number
};

ProposalGroup.defaultProps = {
  numberOfVisibleItems: 3,
  proposals: []
};

ProposalGroup.propTypesMeta = 'exclude';

export default ProposalGroup;
