import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import OPE from 'js/on-page-editing';

import ContentAreaItem from 'components/content-area-item';

const themes = {
  singleColumn: '-theme-single-column',
  twoColumns: '-theme-two-columns',
  threeColumns: '-theme-three-columns',
  fourColumns: '-theme-four-columns',
  portfolio: '-theme-portfolio'
};

/*
NOTE: additionalComponentProps can be used to send props to all components of a specific type.
This only applies to the components rendered directly by ContentAreaItem (in other words these props don't trickle all the way down the render tree)

Example: forcing the 'blue' theme for all InfoBlocks rendered by a content area:
   <ContentArea additionalComponentProps={{ InfoBlock: { theme: InfoBlock.themes.blue } }} />
*/

/*
NOTE: additionalItemProps works in much the same way as 'additionalComponentProps', except for the fact that it controls the ContentAreaItem wrapper instead of the component itself.

Example: forcing full width for all wrappers around InfoBlocks rendered by a content area:
  <ContentArea additionalItemProps={{ InfoBlock: { size: ContentAreaItem.sizes.fullScreen } }} />
*/

// NOTE: enableElementSizing controls both this component and ContentAreaItem. When set to true, ContentArea uses flexbox. When set to false it doesn't, and ContentAreaItems are always rendered full width.

const ContentArea = ({
  additionalComponentProps, // See above comment
  additionalItemProps, // Same as 'additionalComponentProps' but for the ContentAreaItem component
  enableElementSizing, // See above comment
  blocks,
  blockNotSupportedText,
  className,
  onPageEditing,
  theme
}) =>
  blocks.length === 0 && !onPageEditing.name ? null : (
    <div
      className={cn(
        'content-area',
        { '-flex-layout': enableElementSizing },
        theme,
        className
      )}
      {...OPE(onPageEditing.name)}
    >
      {blocks.map(block => (
        <ContentAreaItem
          additionalComponentProps={additionalComponentProps}
          blockNotSupportedText={blockNotSupportedText}
          enableElementSizing={enableElementSizing}
          isStaticLayout={!!theme}
          key={block.id}
          {...block}
          {...additionalItemProps[block.componentName]}
        />
      ))}
    </div>
  );

ContentArea.propTypes = {
  additionalComponentProps: PropTypes.shape(typeof Object),
  additionalItemProps: PropTypes.shape(typeof Object),
  blocks: PropTypes.arrayOf(PropTypes.exact(ContentAreaItem.propTypes)),
  blockNotSupportedText: PropTypes.string,
  className: PropTypes.string,
  enableElementSizing: PropTypes.bool,
  onPageEditing: PropTypes.exact({
    name: PropTypes.string
  }),
  // eslint-disable-next-line @creuna/prop-types-csharp/all
  theme: PropTypes.oneOfType([
    PropTypes.oneOf(Object.values(themes)),
    PropTypes.arrayOf(PropTypes.oneOf(Object.values(themes)))
  ])
};

ContentArea.propTypesMeta = 'exclude';

ContentArea.defaultProps = {
  additionalItemProps: {},
  blocks: [],
  enableElementSizing: true,
  onPageEditing: {}
};

ContentArea.themes = themes;

export default ContentArea;
