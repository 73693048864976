import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Icon from 'components/icon';

const themes = {
  hiddenLabel: '-theme-hidden-label',
  inverted: '-theme-inverted',
  small: '-theme-small',
  rounded: '-theme-rounded'
};

const ifWrapWithIcon = (input, icon, fill) => {
  if (!icon) return input;
  return (
    <div className="text-input--input-wrapper">
      <Icon name={icon} fill={fill} /> {input}
    </div>
  );
};

const TextInput = React.forwardRef((props, ref) => {
  const {
    attributes,
    className,
    controlled,
    idPrefix,
    label,
    name,
    onChange,
    placeholder,
    theme,
    type,
    validationError,
    value,
    icon,
    iconFill
  } = props;

  const id = `input-${idPrefix}-${name}`;
  const valueProp = controlled ? { value } : { defaultValue: value };

  return (
    <div className={cn('text-input', className, theme)}>
      {label && <label htmlFor={id}>{label}</label>}
      {validationError && (
        <div className="text-input--validation-error">{validationError}</div>
      )}
      {ifWrapWithIcon(
        <input
          id={id}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          ref={ref}
          type={type}
          {...valueProp}
          {...attributes}
        />,
        icon,
        iconFill
      )}
    </div>
  );
});

TextInput.propTypes = {
  attributes: PropTypes.shape(typeof Object),
  className: PropTypes.string,
  controlled: PropTypes.bool,
  idPrefix: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  // eslint-disable-next-line @creuna/prop-types-csharp/all
  theme: PropTypes.oneOfType([
    PropTypes.oneOf(Object.values(themes)),
    PropTypes.arrayOf(PropTypes.oneOf(Object.values(themes)))
  ]),
  type: PropTypes.string,
  validationError: PropTypes.string,
  // eslint-disable-next-line @creuna/prop-types-csharp/all
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.string,
  iconFill: PropTypes.bool
};

TextInput.propTypesMeta = 'exclude';

TextInput.defaultProps = {
  attributes: {},
  idPrefix: '',
  onChange: () => {},
  type: 'text'
};

TextInput.themes = themes;

export default TextInput;
