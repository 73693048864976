import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import OPE from 'js/on-page-editing';

import Link from 'components-old/link';

const themes = {
  big: '-theme-big',
  portfolio: '-theme-portfolio'
};

const linkThemes = {
  black: '-theme-black',
  bold: '-theme-bold',
  fullWidth: '-theme-full-width',
  inlineIcon: '-theme-inline-icon',
  orangeIcon: '-theme-orange-icon',
  small: '-theme-small',
  tag: '-theme-tag',
  orangeTag: '-theme-orange-tag',
  underline: '-theme-underline',
  white: '-theme-white',
  portfolio: '-theme-portfolio'
};

const LinkWithText = ({ link, linkTheme, onPageEditing, text, theme }) => (
  <div className={cn('link-with-text', theme)}>
    <h3 {...OPE(onPageEditing.link)}>
      <Link
        iconBeforeChildren="small-arrow-right"
        theme={linkTheme}
        {...link}
      />
    </h3>
    <p className="link-with-text--text" {...OPE(onPageEditing.text)}>
      {text}
    </p>
  </div>
);

LinkWithText.propTypes = {
  link: PropTypes.exact(Link.propTypes),
  linkTheme: PropTypes.oneOf(Object.values(linkThemes)),
  onPageEditing: PropTypes.exact({
    link: PropTypes.string,
    text: PropTypes.string
  }),
  text: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes))
};

LinkWithText.propTypesMeta = 'exclude';

LinkWithText.defaultProps = {
  onPageEditing: {}
};

LinkWithText.themes = themes;

export default LinkWithText;
