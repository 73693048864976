import React from 'react';
import PropTypes from 'prop-types';

import propTypeTheme from 'utils/prop-type-theme';

import cn from 'classnames';

import Accordion from 'components-old/accordion';
import ChapterLink from 'components-old/chapter-link';

const themes = {
  outlineButton: '-theme-outline-button'
};

const ChapterNavigationList = ({
  accordion,
  link,
  isCurrent,
  linkOrLinkList,
  theme
}) => {
  return (
    <Accordion {...accordion}>
      {({ Button, Collapse, isOpen }) => (
        <div className="chapter-navigation-list">
          <div className="chapter-navigation-list--header">
            <ChapterLink isCurrent={isCurrent} {...link} />
            <Button
              icon="small-arrow-down"
              className={cn('chapter-navigation-list--toggle', theme, {
                '-is-active': isOpen
              })}
            />
          </div>

          <Collapse className="chapter-navigation-list--content">
            <ul className="link-list">
              {linkOrLinkList.map(({ link, linkOrLinkList }, index) => {
                if (link) {
                  return (
                    <li key={link.chapterNumber + String(index)}>
                      <ChapterLink {...link} />
                    </li>
                  );
                }

                if (linkOrLinkList) {
                  return (
                    <li
                      className="-has-button"
                      key={linkOrLinkList.title + String(index)}
                    >
                      <ChapterNavigationList
                        theme={themes.outlineButton}
                        {...linkOrLinkList}
                      />
                    </li>
                  );
                }
              })}
            </ul>
          </Collapse>
        </div>
      )}
    </Accordion>
  );
};

ChapterNavigationList.propTypes = {
  accordion: PropTypes.exact(Accordion.propTypes),
  link: PropTypes.exact(ChapterLink.propTypes),
  isCurrent: PropTypes.bool,
  theme: PropTypes.oneOf(Object.values(themes))
};

// PropTypes is mutated because linkOrLinkList refers to ChapterNavgiationList
ChapterNavigationList.propTypes.linkOrLinkList = PropTypes.arrayOf(
  PropTypes.exact({
    linkOrLinkList: PropTypes.exact(ChapterNavigationList.propTypes),
    link: PropTypes.exact(ChapterLink.propTypes)
  })
).isRequired;

ChapterNavigationList.defaultProps = {
  linkOrLinkList: []
};

ChapterNavigationList.propTypesMeta = 'exclude';

ChapterNavigationList.themes = themes;

export default ChapterNavigationList;
