import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/icon';
import Link from 'components/link';

const supportedProviders = [
  'facebook',
  'linkedin',
  'twitter',
  'rss',
  'instagram'
];

const SocialMediaLink = ({ provider, text, url }) => (
  <Link
    className="social-media-link-list--button"
    attributes={{ title: text }}
    url={url}
  >
    <Icon name={`some-${provider}`} fill />
    <span>{text}</span>
  </Link>
);

SocialMediaLink.propTypes = {
  provider: PropTypes.oneOf(supportedProviders),
  url: PropTypes.string,
  text: PropTypes.string
};

export default SocialMediaLink;
