import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'components/icon/icon';

const types = {
  rectangle: 'rectangle',
  circle: 'circle',
  snakeH: 'snake-horizontal',
  snakeV: 'snake-vertical',
  longH: 'long-horizontal',
  longV: 'long-vertical'
};

const colors = {
  orange: '#ffb74c',
  red: '#ff6d55',
  teal: '#6ed5e4',
  purple: '#26266d',
  green: '#00b982',
  pink: '#b35da9'
};

const Shape = ({ type, color, className }) => {
  return (
    <Icon
      className={cn('shape', className)}
      style={{ color }}
      name={`np-${type}`}
      fill
    />
  );
};

Shape.propTypes = {
  type: PropTypes.oneOf(Object.values(types)),
  color: PropTypes.oneOf(Object.values(colors)),
  className: PropTypes.string
};

Shape.propTypesMeta = 'exclude';

Shape.colors = colors;
Shape.types = types;

export default Shape;
