import React from 'react';
import PropTypes from 'prop-types';

import FluidImage from 'components/fluid-image';
import background1 from '../../assets/images/event-background.svg';
import background2 from '../../assets/images/event-background2.svg';
import background3 from '../../assets/images/event-background3.svg';
import background4 from '../../assets/images/event-background4.svg';

import background1grey from '../../assets/images/event-background-grey.svg';
import background2grey from '../../assets/images/event-background2-grey.svg';
import background3grey from '../../assets/images/event-background3-grey.svg';
import background4grey from '../../assets/images/event-background4-grey.svg';

const normalBackgrounds = {
  background1,
  background2,
  background3,
  background4
};

const greyBackgrounds = {
  background1: background1grey,
  background2: background2grey,
  background3: background3grey,
  background4: background4grey
};

let count = 0;

const getBackground = backgrounds => {
  const index = count++ % 3;
  return Object.values(backgrounds)[index];
};

const EventImage = ({ image = {}, background, darkFilter, greyFilter }) => {
  const backgrounds = greyFilter ? greyBackgrounds : normalBackgrounds;
  const eventImage = {
    src: image.src || backgrounds[background] || getBackground(backgrounds),
    alt: image.alt || 'background',
    focusPoint: image.focusPoint
  };

  return (
    <div className="event-image">
      <FluidImage
        {...eventImage}
        darkFilter={darkFilter}
        theme={FluidImage.themes.roundedSmall}
      />
    </div>
  );
};

EventImage.propTypes = {
  image: PropTypes.exact(FluidImage.propTypes),
  background: PropTypes.oneOf(Object.keys(normalBackgrounds)),
  darkFilter: PropTypes.bool,
  greyFilter: PropTypes.bool
};

EventImage.propTypesMeta = 'exclude';

export default EventImage;
