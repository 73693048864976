import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import FluidImage from 'components-old/fluid-image';

const sizes = {
  smallImage: '-size-small'
};

const ListItemImage = ({ image, size }) => {
  return (
    <div className={cn('list-item-image', size)}>
      <div className="list-item-image__rectangle">
        <FluidImage {...image} />
      </div>
    </div>
  );
};

ListItemImage.propTypes = {
  image: PropTypes.exact(FluidImage.propTypes),
  size: PropTypes.oneOf(Object.values(sizes))
};

ListItemImage.propTypesMeta = 'exclude';

ListItemImage.sizes = sizes;

export default ListItemImage;
