import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Heading from 'components/heading';
import Table from 'components/table';
import RichText from 'components/rich-text';
import useIsScreenSize from 'js/hooks/use-is-screen-size';
import TableMobile from 'components/table-mobile';

const TableBlock = ({ table, mobileList, title, richText, isWide }) => {
  const isMobile = useIsScreenSize('mdMax');

  return (
    <div className={cn('table-block', { '-wide': isWide })}>
      {!isMobile ? (
        <Table {...table} title={title} />
      ) : (
        <>
          {mobileList ? (
            <>
              {title && <Heading className="table--title">{title}</Heading>}

              <TableMobile {...mobileList} title={title} />
            </>
          ) : (
            <Table {...table} title={title} />
          )}
        </>
      )}
      {richText && <RichText {...richText} />}
    </div>
  );
};

TableBlock.propTypes = {
  table: PropTypes.exact(Table.propTypes).isRequired,
  title: PropTypes.string.isRequired,
  richText: PropTypes.exact(RichText.propTypes),
  mobileList: PropTypes.exact(TableMobile.propTypes),
  isWide: PropTypes.bool
};

TableBlock.propTypesMeta = 'exclude';

export default TableBlock;
