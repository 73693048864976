import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Byline from 'components/byline';
import Heading from 'components/heading';
import LinkWrapper from 'components/link-wrapper';
import Published from 'components/published';
import TagLinkList from 'components/tag-link-list';
import TagList from 'components/tag-list';
import ListItemImage from 'components/list-item-image';
import FluidImage from 'components/fluid-image';
import Link from 'components/link';
import propTypeTheme from 'utils/prop-type-theme';
import Metadata from 'components/metadata';
import DocumentIcon from 'components/document-icon';
import EventImage from 'components/event-image/event-image';
import doesContain from 'js/utils/doesContain';

const themes = {
  noMargin: '-theme-no-margin',
  withBorder: '-theme-with-border',
  squareImage: '-theme-square-image',
  sidebar: '-theme-sidebar',
  vertical: '-theme-vertical',
  publication: '-theme-publication'
};

const ArticleBlock = ({
  byline,
  headingLevel,
  image,
  text,
  theme,
  title,
  published,
  linkTags,
  tags,
  usedInSidebar,
  metadata,
  icon,
  documentImage,
  eventImage
}) => {
  const { OuterLink, InnerLink } = LinkWrapper(true, title.url);
  const isPublication = doesContain(theme, themes.publication);

  return (
    <OuterLink
      className={cn('article-block', theme)}
      attributes={{ 'aria-label': title.text }}
    >
      <div className={cn('article-block__content', theme)} key={text}>
        {image && !isPublication && (
          <ListItemImage
            image={image}
            size={usedInSidebar ? ListItemImage.sizes.smallImage : undefined}
          />
        )}
        {(icon || documentImage || isPublication) && (
          <div className="article-block--event-image-container">
            <EventImage {...eventImage} image={image} greyFilter />
            <div className="article-block--document-container">
              {icon && (
                <DocumentIcon {...icon} size={DocumentIcon.sizes.xlarge} />
              )}
              {documentImage && (
                <div className="article-block--document-image">
                  <FluidImage
                    {...documentImage}
                    theme={FluidImage.themes.anchored}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        <div className="article-block__text-container">
          {tags && <TagList {...tags} />}
          <InnerLink>
            {title.text && (
              <Heading
                level={headingLevel}
                className={cn('article-block--title', theme)}
              >
                {title.text}
              </Heading>
            )}
          </InnerLink>
          {published && <Published {...published} />}
          {metadata && <Metadata {...metadata} />}
          {byline && <Byline theme={Byline.themes.border} {...byline} />}
          {text && <p className="article-block--text">{text}</p>}
          {linkTags && <TagLinkList {...linkTags} />}
        </div>
      </div>
    </OuterLink>
  );
};

ArticleBlock.propTypes = {
  byline: PropTypes.exact(Byline.propTypes),
  headingLevel: PropTypes.number,
  title: PropTypes.exact(Link.propTypes),
  image: PropTypes.exact(FluidImage.propTypes),
  text: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes)),
  published: PropTypes.exact(Published.propTypes),
  linkTags: PropTypes.exact(TagLinkList.propTypes),
  tags: PropTypes.exact(TagList.propTypes),
  usedInSidebar: PropTypes.bool,
  metadata: PropTypes.exact(Metadata.propTypes),
  icon: PropTypes.exact(DocumentIcon.propTypes),
  documentImage: PropTypes.exact(FluidImage.propTypes),
  eventImage: PropTypes.exact(EventImage.propTypes)
};

ArticleBlock.propTypesMeta = 'exclude';

ArticleBlock.defaultProps = {
  headingLevel: 2
};

ArticleBlock.themes = themes;

export default ArticleBlock;
