import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/icon';
import Link from 'components/link/link';

const Announcement = ({ groupTag, title, subTitle, links }) => {
  return (
    <div className="announcement">
      <div className="announcement__wrapper">
        <div className="announcement__heading">
          <Icon className="announcement__icon" name="info" fill />
          <h2 className="announcement__title">{title}</h2>
          <p className="announcement__sub-title">{subTitle}</p>
        </div>
        {links.length > 0 && (
          <div className="announcement__link-wrapper">
            {links.map(link => (
              <div className="announcement__link-inner" key={link.id}>
                <span className="announcement__link-label">{link.label}</span>
                <a className="announcement__link" href={link.url} key={link.id}>
                  {link.text}
                </a>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

Announcement.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  groupTag: PropTypes.string,
  links: PropTypes.arrayOf(Link.propTypes)
};

Announcement.defaultProps = {
  links: []
};

export default Announcement;
