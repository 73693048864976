import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Accordion from 'components/accordion';
import Byline from 'components/byline';
import Link from 'components/link';
import OptionsModal from 'components/options-modal';

const ArticleHeader = ({ accordion, byline, links, share, isLargeArticle }) => {
  return (
    <div
      className={cn('article-header', { '-is-large-article': isLargeArticle })}
    >
      {!!byline.items.length && (
        <React.Fragment>
          {/* Mobile version */}
          {accordion ? (
            <Accordion {...accordion}>
              {({ Button, Collapse, isOpen, toggle }) => (
                <div className="article-header--byline -is-mobile">
                  <div className="article-header--byline-header">
                    <p onClick={toggle}>{byline.items[0].text}</p>
                    <Button
                      className={cn('article-header--toggle', {
                        '-is-active': isOpen
                      })}
                      icon="micro-arrow-down"
                    />
                  </div>
                  <Collapse>
                    <div className="article-header--byline-content">
                      <Byline items={byline.items.slice(1)} />
                      {share && (
                        <div className="article-header--links">
                          <OptionsModal
                            {...share}
                            theme="-is-article-header"
                          ></OptionsModal>
                        </div>
                      )}
                      {links &&
                        links.map(link => (
                          <div className="article-header--download-label">
                            <Link
                              {...link}
                              iconBeforeChildren="download-round"
                            />
                          </div>
                        ))}
                    </div>
                  </Collapse>
                </div>
              )}
            </Accordion>
          ) : (
            <div className="article-header--byline -is-mobile">
              <div className="article-header--byline-content">
                <Byline items={byline.items.slice(1)} />
                {links &&
                  links.map(link => (
                    <div className="article-header--download-label">
                      <Link {...link} />
                    </div>
                  ))}
              </div>
            </div>
          )}

          {/* Desktop version */}
          <div className="article-header--byline -is-desktop">
            <Byline {...byline} />

            <div className="article-header--links -is-desktop">
              {share && (
                <OptionsModal
                  {...share}
                  theme="-is-article-header"
                ></OptionsModal>
              )}
              {links &&
                links.map(link => (
                  <div className="article-header--download-link">
                    <Link {...link} iconBeforeChildren="download-round" />
                  </div>
                ))}
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

ArticleHeader.propTypes = {
  accordion: PropTypes.exact(Accordion.propTypes),
  byline: PropTypes.exact(Byline.propTypes),
  links: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  share: PropTypes.exact(OptionsModal.propTypes),
  isLargeArticle: PropTypes.bool
};

ArticleHeader.defaultProps = {
  byline: {
    items: []
  }
};

ArticleHeader.propTypesMeta = 'exclude';

export default ArticleHeader;
