import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Icon from 'components-old/icon';
import propTypeTheme from 'js/utils/prop-type-theme';

const themes = {
  hiddenLabel: '-theme-hidden-label',
  inverted: '-theme-inverted',
  small: '-theme-small',
  rounded: '-theme-rounded',
  orangeFocus: '-theme-orange-focus'
};

const ifWrapWithIcon = (input, icon) => {
  if (!icon) return input;
  return (
    <div className="text-input--input-wrapper">
      <Icon name={icon} fill /> {input}
    </div>
  );
};

const TextInput = React.forwardRef((props, ref) => {
  const {
    attributes,
    className,
    controlled,
    idPrefix,
    label,
    name,
    onChange,
    placeholder,
    theme,
    type,
    validationError,
    value,
    icon
  } = props;

  const id = `input-${idPrefix}-${name}`;
  const valueProp = controlled ? { value } : { defaultValue: value };

  return (
    <div className={cn('text-input', className, theme)}>
      {label && <label htmlFor={id}>{label}</label>}
      {validationError && (
        <div className="text-input--validation-error">{validationError}</div>
      )}
      {ifWrapWithIcon(
        <input
          id={id}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          ref={ref}
          type={type}
          {...valueProp}
          {...attributes}
        />,
        icon
      )}
    </div>
  );
});

TextInput.propTypes = {
  attributes: PropTypes.shape(typeof Object),
  className: PropTypes.string,
  controlled: PropTypes.bool,
  idPrefix: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes)),
  type: PropTypes.string,
  validationError: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.string
};

TextInput.propTypesMeta = 'exclude';

TextInput.defaultProps = {
  attributes: {},
  idPrefix: '',
  onChange: () => {},
  type: 'text'
};

TextInput.themes = themes;

export default TextInput;
