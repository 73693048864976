import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// Hacky loading because stickyfilljs directly references 'window', which crashes server side rendering.
import isRunningOnClient from '@creuna/utils/is-running-on-client';
const stickyPolyfill = isRunningOnClient ? require('stickyfilljs') : {};
import StickyMenuOnTabsItem from './sticky-menu-on-tabs-item';
import Link from 'components-old/link';

const StickyMenuOnTabs = ({ navGroups, title, className }) => {
  const [enableScroll, setEnableScroll] = useState(false);
  const element = React.useRef();

  useEffect(() => {
    stickyPolyfill.addOne(element.current);
  }, []);

  const setScroll = menu => {
    if (menu) {
      const shouldScroll = window.innerHeight < menu.offsetHeight + 50;
      if (shouldScroll !== enableScroll) setEnableScroll(shouldScroll);
    }
    element.current = menu;
  };

  const scroll = enableScroll
    ? { overflowY: 'scroll' }
    : { overflowY: 'hidden' };

  return (
    <div className={cn('sticky-menu-on-tabs', className)}>
      <div
        className="sticky-menu-on-tabs--content"
        ref={setScroll}
        style={scroll}
      >
        {title && <h3 className="sticky-menu-on-tabs--title">{title}</h3>}
        <nav>
          {navGroups.map(({ titleLink = {}, links }, index) => (
            <React.Fragment key={`${titleLink.text}-${index}`}>
              {titleLink.text && (
                <Link
                  className="sticky-menu-on-tabs--title-link"
                  {...titleLink}
                />
              )}
              {links.items && !!links.items.length && (
                <ul>
                  {links.items.map(item => (
                    <StickyMenuOnTabsItem {...item} key={item.link.url} />
                  ))}
                </ul>
              )}
            </React.Fragment>
          ))}
        </nav>
      </div>
    </div>
  );
};

StickyMenuOnTabs.propTypes = {
  navGroups: PropTypes.arrayOf(
    PropTypes.exact({
      titleLink: PropTypes.exact(Link.propTypes),
      links: PropTypes.shape({
        items: PropTypes.arrayOf(
          PropTypes.exact(StickyMenuOnTabsItem.propTypes)
        ).isRequired
      })
    })
  ),
  title: PropTypes.string,
  includeHeader: PropTypes.bool,
  className: PropTypes.string
};

StickyMenuOnTabs.defaultProps = {
  navGroups: []
};

StickyMenuOnTabs.propTypesMeta = 'exclude';

export default StickyMenuOnTabs;
