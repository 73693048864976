import React from 'react';
import PropTypes from 'prop-types';

const StepTag = ({ groupTag }) => (
  <>{groupTag && <p className="step-tag">{groupTag}</p>}</>
);

StepTag.propTypes = {
  groupTag: PropTypes.string
};

export default StepTag;
