import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Accordion from 'components/accordion';
import Checkbox from 'components/form-elements/checkbox';
import Icon from 'components/icon';

const FilterGroup = ({
  accordion,
  options,
  title,
  updateFilterOptions,
  isDesktop,
  dataId
}) => {
  const container = React.useRef();

  return !isDesktop ? (
    <Accordion {...accordion} buttonTextForLabel={title}>
      {({ Button: AccordionButton, Collapse, isOpen, toggle }) => (
        <div className="filter-group" ref={container}>
          <AccordionButton
            className="filter-group--header"
            onClick={toggle}
            text={null}
          >
            <h2 className="filter-group--header-title">
              <span> {title}</span>
              <Icon
                className={cn('filter-group--icon', {
                  '-is-open': isOpen
                })}
                name="small-arrow-down"
                fill={true}
              />
            </h2>
          </AccordionButton>

          <Collapse>
            <div className="filter-group__content">
              <ul className="filter-group--options">
                {options.map(option => (
                  <li key={option.value}>
                    <Checkbox
                      theme={Checkbox.themes.blue}
                      onChange={updateFilterOptions}
                      dataId={dataId}
                      isControlled
                      {...option}
                    />
                    {option.subOptions && (
                      <ul className="filter-group--sub-options">
                        {option.subOptions.map(subOption => (
                          <li
                            className="filter-group--sub-option"
                            key={subOption.value}
                          >
                            <Checkbox
                              theme={Checkbox.themes.blue}
                              onChange={updateFilterOptions}
                              dataId={dataId}
                              isControlled
                              {...subOption}
                            />
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </Collapse>
        </div>
      )}
    </Accordion>
  ) : (
    <div className="filter-group-open">
      <div className="filter-group-open__content">
        {title && <div className="filter-group-open__title">{title}</div>}
        <ul className="filter-group-open--options">
          {options.map(option => (
            <li key={option.value}>
              <Checkbox
                theme={Checkbox.themes.blue}
                onChange={updateFilterOptions}
                dataId={dataId}
                isControlled
                {...option}
              />
              {option.subOptions && (
                <ul className="filter-group-open--sub-options">
                  {option.subOptions.map(subOption => (
                    <li
                      className="filter-group-open--sub-option"
                      key={subOption.value}
                    >
                      <Checkbox
                        onChange={updateFilterOptions}
                        theme={Checkbox.themes.blue}
                        dataId={dataId}
                        isControlled
                        {...subOption}
                      />
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

FilterGroup.propTypes = {
  accordion: PropTypes.exact(Accordion.propTypes),
  parent: PropTypes.node,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      checked: PropTypes.bool,
      name: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string,
      subOptions: PropTypes.arrayOf(PropTypes.exact(Checkbox.propTypes))
    })
  ),
  title: PropTypes.string,
  setSearchQueryParameterHelper: PropTypes.func,
  isDesktop: PropTypes.bool,
  dataId: PropTypes.number,
  updateFilterOptions: PropTypes.func
};

FilterGroup.defaultProps = {
  options: []
};

FilterGroup.propTypesMeta = 'exclude';

export default FilterGroup;
