import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components-old/button';
import EmptyList from 'components-old/empty-list';
import Link from 'components-old/link';
import cn from 'classnames';
import Heading from 'components-old/heading';
import AutoGrid from 'components-old/auto-grid';

const borders = {
  top: '-border-top',
  bottom: '-border-bottom'
};

const RelatedContent = ({
  children,
  emptyList,
  link,
  onPageEditing,
  title,
  border,
  isGrid,
  itemMinWidth
}) => {
  const gridLinkThemes = [Button.themes.orangeOutline, Button.themes.big];
  return (
    <div className={cn('related-content', { '-is-grid': isGrid })}>
      {title && (
        <Heading
          className="related-content--title"
          onPageEditing={onPageEditing.title}
        >
          {title}
        </Heading>
      )}
      {React.Children.count(children) > 0 ? (
        <AutoGrid isList={!isGrid} minWidth={itemMinWidth}>
          {AutoGridItem =>
            React.Children.map(children, child => (
              <AutoGridItem
                className={cn(
                  null,
                  { 'related-content--item': !isGrid },
                  borders[border]
                )}
              >
                {child}
              </AutoGridItem>
            ))
          }
        </AutoGrid>
      ) : (
        <EmptyList {...emptyList} />
      )}

      {link && (
        <div className="related-content--link">
          <Link
            className="related-content--link-inner"
            theme={isGrid ? gridLinkThemes : Button.themes.orangeOutline}
            useButtonStyles
            {...link}
          />
        </div>
      )}
    </div>
  );
};

RelatedContent.propTypes = {
  children: PropTypes.node,
  emptyList: PropTypes.exact(EmptyList.propTypes),
  link: PropTypes.exact(Link.propTypes),
  onPageEditing: PropTypes.exact({
    title: PropTypes.string
  }),
  title: PropTypes.string,
  border: PropTypes.oneOf(Object.keys(borders)),
  isGrid: PropTypes.bool,
  itemMinWidth: PropTypes.number
};

RelatedContent.defaultProps = {
  border: 'bottom',
  onPageEditing: {}
};

RelatedContent.propTypesMeta = 'exclude';

export default RelatedContent;
