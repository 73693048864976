import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import TextInput from 'components/form-elements/text-input';

const InputEmail = ({
  name,
  label,
  value,
  validationError,
  isValid,
  setIsValid
}) => {
  const onChange = e => {
    const input = e.target.value;
    const isValidEmail = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i.test(
      input
    );
    setIsValid(isValidEmail);
  };
  return (
    <div className={cn('input-email', { '-invalid': isValid === false })}>
      <TextInput
        theme={TextInput.themes.orangeFocus}
        onChange={onChange}
        name={name}
        value={value}
        label={label}
        type="text"
        icon="mail-square"
        iconFill
      />
      {isValid === false && (
        <div className="input-email--error">{validationError}</div>
      )}
    </div>
  );
};

InputEmail.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  validationError: PropTypes.string,
  isValid: PropTypes.bool,
  value: PropTypes.string,
  setIsValid: PropTypes.func
};

InputEmail.propTypesMeta = 'exclude';
export default InputEmail;
