import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import fallback from 'images/fallback.svg';
import excel from 'images/xls.svg';
import pdf from 'images/pdf.svg';
import ppt from 'images/ppt.svg';
import word from 'images/doc.svg';
import csv from 'images/csv.svg';
import image from 'images/image.svg';

import csvRequired from 'images/csv-required.svg';
import imageRequired from 'images/image-required.svg';
import fallbackRequired from 'images/fallback-required.svg';
import excelRequired from 'images/xls-required.svg';
import pdfRequired from 'images/pdf-required.svg';
import pptRequired from 'images/ppt-required.svg';
import wordRequired from 'images/doc-required.svg';

import csvBlack from 'images/csv-black.svg';
import imageBlack from 'images/image-black.svg';
import fallbackBlack from 'images/fallback-black.svg';
import excelBlack from 'images/xls-black.svg';
import pdfBlack from 'images/pdf-black.svg';
import pptBlack from 'images/ppt-black.svg';
import wordBlack from 'images/doc-black.svg';

const sizes = {
  xsmall: '-size-xsmall',
  small: '-size-small',
  large: '-size-large',
  xlarge: '-size-xlarge'
};

const iconThemes = {
  word: { icon: word, required: wordRequired, black: wordBlack },
  pdf: { icon: pdf, required: pdfRequired, black: pdfBlack },
  ppt: { icon: ppt, required: pptRequired, black: pptBlack },
  excel: { icon: excel, required: excelRequired, black: excelBlack },
  fallback: {
    icon: fallback,
    required: fallbackRequired,
    black: fallbackBlack
  },
  csv: { icon: csv, required: csvRequired, black: csvBlack },
  image: { icon: image, required: imageRequired, black: imageBlack }
};

const DocumentIcon = ({ iconTheme, size, isRequired, isBlack, className }) => {
  const icon = (function () {
    const iconObj = iconThemes[iconTheme];
    if (isRequired && iconObj.required) return iconObj.required;
    if (isBlack && iconObj.black) return iconObj.black;
    return iconObj.icon;
  })();

  const backgroundImage = { backgroundImage: `url(${icon})` };

  return (
    <div className={cn('document-icon', className)}>
      <div
        className={cn('document-icon--icon', iconTheme, size)}
        style={backgroundImage}
      />
    </div>
  );
};

DocumentIcon.propTypes = {
  iconTheme: PropTypes.oneOf(Object.keys(iconThemes)),
  size: PropTypes.oneOf(Object.values(sizes)),
  isRequired: PropTypes.bool,
  isBlack: PropTypes.bool,
  className: PropTypes.string
};

DocumentIcon.defaultProps = {
  iconTheme: 'fallback'
};

DocumentIcon.propTypesMeta = 'exclude';

DocumentIcon.sizes = sizes;

export default DocumentIcon;
