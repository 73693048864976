import React from 'react';

import cn from 'classnames';

import Icon from 'components/icon';
import TextInput from '../text-input';

/* eslint-disable react/prop-types */
const SearchInput = ({ className, idPrefix, label, name, placeholder }) => (
  <div className={cn('search-input', className)}>
    <Icon className="search-input--icon" name="search" />
    <TextInput
      className="search-input--input"
      idPrefix={idPrefix}
      label={label}
      name={name}
      placeholder={placeholder}
      theme={[TextInput.themes.hiddenLabel]}
      type="search"
    />
  </div>
);

SearchInput.propTypes = TextInput.propTypes;

SearchInput.themes = TextInput.themes;

export default SearchInput;
