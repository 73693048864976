import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/button';
import ContentContainer from 'components/content-container';
import Link from 'components/link';
import Message from 'components/message';
import TibiProposalData from './tibi-proposal-data';
import ProposalContact from '../proposal-page/proposal-contact';
import TimelineHorizontal from 'components/timeline-horizontal';
import Icon from 'components/icon';
import OptionsModal from 'components/options-modal';
import DateCardStatus from 'components/date-card/date-card-status';
import ArticleHeader from 'components/article-header';
import TimelineVertical from 'components/timeline-vertical';
import Tabs from 'components/tabs';
import Status from 'components/status';
import ContentArea from 'components/content-area';
import RichText from 'components/rich-text';
import Announcement from 'components/announcement/announcement';
import StepTag from 'components/step-tag/step-tag';

const TibiProposalPage = ({
  applyButton,
  contacts,
  contactLabel,
  download,
  metaDataTitle,
  metadataLeft,
  arbitraryMetadata,
  metadataRight,
  message,
  tabs,
  title,
  preamble,
  timelineHorizontal,
  headerContentArea,
  footerContentArea,
  goBack,
  isInternational,
  statusList,
  header,
  timelineVertical,
  siblings
}) => (
  <div className="tibi-proposal-page">
    <ContentContainer>
      {goBack && (
        <a
          href={goBack.url}
          className="button -theme-secondary tibi-proposal-page__back-button"
        >
          <Icon name="arrow-down" />
          {goBack.text}
        </a>
      )}
      {siblings?.groupTag && (
        <div className="tibi-proposal-page__step-tag-container">
          <StepTag groupTag={siblings.groupTag} />
        </div>
      )}
      {statusList && (
        <div className="tibi-proposal-page__status-container">
          {statusList.map(status => (
            <Status {...status} key={status.text} />
          ))}
        </div>
      )}
      {title && (
        <h1 className="tibi-proposal-page--heading" data-epi-type="title">
          {isInternational && (
            <Icon className="tibi-proposal-page--icon" name="globe"></Icon>
          )}
          {title}
        </h1>
      )}
      {preamble && (
        <div className="tibi-proposal-page--preamble" data-epi-type="content">
          <RichText {...preamble} />
        </div>
      )}
    </ContentContainer>
    <ContentContainer>
      {siblings && siblings.links.length > 0 && <Announcement {...siblings} />}
    </ContentContainer>

    <div className="tibi-proposal-page--header">
      <ContentContainer className="tibi-proposal-page__header-container">
        {header && <ArticleHeader {...header} />}
        {metaDataTitle && (
          <h2 className="tibi-proposal-page--header-preamble">
            {metaDataTitle}
          </h2>
        )}
        <div className="tibi-proposal-data" data-epi-type="content">
          <TibiProposalData {...metadataLeft} />
          <TibiProposalData
            {...metadataRight}
            contacts={contacts}
            contactLabel={contactLabel}
            rightColumn={true}
          />
        </div>
        {arbitraryMetadata.items && arbitraryMetadata.items.length > 0 && (
          <div
            className="tibi-proposal-data -arbitrary"
            data-epi-type="content"
          >
            <TibiProposalData {...arbitraryMetadata} arbitrary={true} />
          </div>
        )}

        <div className="tibi-proposal-page--header-actions">
          <Link
            className="tibi-proposal-page--apply-button"
            theme={[Button.themes.primary]}
            useButtonStyles
            {...applyButton}
          />
          {download && (
            <OptionsModal
              className="tibi-proposal-page--download"
              theme={OptionsModal.themes.isProposalOrEvent}
              {...download}
            />
          )}
        </div>
      </ContentContainer>
    </div>
    {message && (
      <ContentContainer className="proposal-page--message-container">
        <Message {...message} />
      </ContentContainer>
    )}
    {timelineHorizontal && <TimelineHorizontal {...timelineHorizontal} />}
    {timelineVertical && (
      <TimelineVertical isBlock={false} {...timelineVertical} />
    )}
    {/* content-container above tabs */}
    <ContentContainer>
      <ContentArea
        className="tibi-proposal-page--top-content-area"
        {...headerContentArea}
      />
    </ContentContainer>
    {/* main content */}
    {tabs && <Tabs {...tabs} />}
    {/* content-container below tabs */}
    <ContentContainer>
      <ContentArea
        className="tibi-proposal-page--bottom-content-area"
        {...footerContentArea}
      />
    </ContentContainer>
  </div>
);

TibiProposalPage.propTypes = {
  applyButton: PropTypes.exact(Link.propTypes),
  contacts: PropTypes.arrayOf(PropTypes.exact(ProposalContact.propTypes)),
  contactLabel: PropTypes.string,
  download: PropTypes.exact(OptionsModal.propTypes),
  header: PropTypes.exact(ArticleHeader.propTypes),
  metaDataTitle: PropTypes.string,
  metadataLeft: PropTypes.exact(TibiProposalData.propTypes),
  metadataRight: PropTypes.exact(TibiProposalData.propTypes),
  arbitraryMetadata: PropTypes.exact(TibiProposalData.propTypes),
  message: PropTypes.exact(Message.propTypes),
  tabs: PropTypes.exact(Tabs.propTypes),
  timelineHorizontal: PropTypes.exact(TimelineHorizontal.propTypes),
  headerContentArea: PropTypes.exact(ContentArea.propTypes),
  footerContentArea: PropTypes.exact(ContentArea.propTypes),
  listUrl: PropTypes.string,
  title: PropTypes.string,
  preamble: PropTypes.exact(RichText.propTypes),
  isInternational: PropTypes.bool,
  share: PropTypes.exact(OptionsModal.propTypes),
  statusList: PropTypes.arrayOf(PropTypes.exact(DateCardStatus.propTypes)),
  timelineVertical: PropTypes.exact(TimelineVertical.propTypes),
  siblings: PropTypes.exact(Announcement.propTypes),
  goBack: PropTypes.exact(Link.propTypes)
};

TibiProposalPage.defaultProps = 'exclude';

export default TibiProposalPage;
