import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import propTypeTheme from 'utils/prop-type-theme';

import ContentContainer from 'components-old/content-container';
import EmptyList from 'components-old/empty-list';
import FilterLayout from 'components-old/filter-layout';
import Form from 'components-old/form';
import GroupedSearchPageLink from 'components-old/grouped-search-page-link';
import Pagination from 'components-old/pagination';
import Search from 'components-old/search';
import SearchResultGroup from 'components-old/search-result-group';
import Spinner from 'components-old/spinner';
import useFetchFilteredResults from 'js/hooks/use-fetch-filtered-results';

const themes = {
  lowercase: '-theme-lowercase'
};

const GroupedSearchPage = ({
  emptyList,
  filterLayout,
  form,
  navigation,
  pagination,
  results,
  resultsDescription,
  search,
  title,
  theme,
  fetchFilteredResultsEndpoint
}) => {
  const [
    isLoading,
    {
      results: resultsState,
      pagination: paginationState,
      resultsDescription: resultsDescriptionState,
      emptyList: emptyListState,
      search: searchState,
      filterLayout: filterLayoutState,
      navigation: navigationState
    },
    fetchResults
  ] = useFetchFilteredResults(
    {
      results,
      pagination,
      resultsDescription,
      emptyList,
      search,
      filterLayout,
      navigation
    },
    fetchFilteredResultsEndpoint,
    form.endpoint,
    search.input
  );

  const fetchResultsWithSearch = searchQueryParameter => {
    fetchResults(searchQueryParameter, search.input);
  };

  const topContent = (
    <div className="grouped-search-page--results-description">
      {resultsDescriptionState}
    </div>
  );

  return (
    <Form showSubmitButton={false} {...form}>
      <ContentContainer className={cn('grouped-search-page', theme)}>
        {title && <h1>{title}</h1>}
        <Search {...searchState} />

        <nav>
          <ul>
            {navigationState &&
              navigationState.map(item => (
                <li key={item.link.text}>
                  <GroupedSearchPageLink {...item} />
                </li>
              ))}
          </ul>
        </nav>
      </ContentContainer>
      <FilterLayout
        {...filterLayoutState}
        topContent={topContent}
        fetchFilteredResults={fetchResultsWithSearch}
        isLoading={isLoading}
      >
        <React.Fragment>
          {resultsState.length > 0 ? (
            resultsState.map((result, index) => (
              <SearchResultGroup
                key={result.title + String(index)}
                {...result}
              />
            ))
          ) : (
            <EmptyList {...emptyListState} />
          )}
          <Spinner isActive={isLoading} />
        </React.Fragment>
        <Pagination {...paginationState} />
      </FilterLayout>
    </Form>
  );
};

GroupedSearchPage.defaultProps = {
  navigation: [],
  results: [],
  theme: []
};

GroupedSearchPage.propTypes = {
  emptyList: PropTypes.exact(EmptyList.propTypes),
  filterLayout: PropTypes.exact(FilterLayout.propTypes),
  form: PropTypes.exact(Form.propTypes),
  navigation: PropTypes.arrayOf(
    PropTypes.exact(GroupedSearchPageLink.propTypes)
  ),
  pagination: PropTypes.exact(Pagination.propTypes),
  results: PropTypes.arrayOf(PropTypes.exact(SearchResultGroup.propTypes)),
  resultsDescription: PropTypes.string,
  search: PropTypes.exact(Search.propTypes),
  theme: PropTypes.oneOf(Object.values(themes)),
  title: PropTypes.string,
  fetchFilteredResultsEndpoint: PropTypes.string
};

GroupedSearchPage.propTypesMeta = 'exclude';

GroupedSearchPage.themes = themes;

export default GroupedSearchPage;
