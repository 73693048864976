import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import SocialMediaLink from './social-media-link';

const themes = {
  white: '-theme-white'
};

const SocialMediaLinkList = ({ className, items, theme }) => (
  <div className={cn('social-media-link-list', theme, className)}>
    {items.map(link => (
      <SocialMediaLink key={link.url} {...link} />
    ))}
  </div>
);

SocialMediaLinkList.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.exact(SocialMediaLink.propTypes)),
  theme: PropTypes.oneOf(Object.values(themes))
};

SocialMediaLinkList.defaultProps = {
  items: []
};

SocialMediaLinkList.propTypesMeta = 'exclude';

SocialMediaLinkList.themes = themes;

export default SocialMediaLinkList;
