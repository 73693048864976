import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import propTypeTheme from 'utils/prop-type-theme';

const themes = {
  small: '-theme-small',
  medium: '-theme-medium'
};

const DateField = ({ date, transparent, theme }) => {
  return (
    <div
      className={cn(
        'date-field',
        {
          '-transparent-background': transparent
        },
        theme
      )}
    >
      {date}
    </div>
  );
};

DateField.themes = themes;

DateField.propTypes = {
  date: PropTypes.string,
  transparent: PropTypes.bool,
  theme: PropTypes.oneOf(Object.values(themes))
};

DateField.propTypesMeta = 'exclude';

export default DateField;
