import React, { useEffect, useState, useRef } from 'react';
import Card from 'components/card';
import DatesMore from 'components/dates-more';
import PropTypes from 'prop-types';
import DataTagList from 'components/data-tag-list';
import Heading from 'components/heading';
import TagLinkList from 'components/tag-link-list';

const CategoryListItem = ({ cardData, linkTags }) => {
  const [datesTextWidth, setDatesTextWidth] = useState();
  const dateTextRef = useRef();

  useEffect(() => {
    if (dateTextRef.current) {
      setDatesTextWidth(dateTextRef.current.offsetWidth);
    }
  }, []);

  return (
    <div className="category-list-item">
      <Card {...cardData} />
      <div className="category-list-item--info-section">
        <div className="card-info-desktop">
          {cardData.preTitle && (
            <div className="card--date">
              <span className="card--date-dot" />
              <time datetime={cardData.preTitle} className="card--date-text">
                {cardData.preTitle}
              </time>
            </div>
          )}

          {cardData.title && (
            <a className="card--title" href={cardData.link.url}>
              <Heading level={2}>{cardData.title}</Heading>
            </a>
          )}
          {cardData.dataTagList && <DataTagList list={cardData.dataTagList} />}

          {cardData.text && <p className="card--text">{cardData.text}</p>}
        </div>
        {linkTags && <TagLinkList {...linkTags} />}
      </div>
    </div>
  );
};

CategoryListItem.propTypes = {
  cardData: PropTypes.shape(Card.propTypes),
  linkTags: PropTypes.exact(TagLinkList.propTypes)
};

export default CategoryListItem;
