import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import useTooltip from 'js/hooks/use-tooltip';
import Tooltip from 'components/tooltip';

const GroupedSearchPageSectionFilterList = ({
  fetchResultsWithSection,
  list,
  hasLowerCaseSectionFilters
}) => {
  const {
    ref,
    handleMouseEnter,
    handleMouseLeave,
    showTabTooltip,
    showTooltip
  } = useTooltip();

  return (
    <>
      {list && (
        <ul className="grouped-search-page-section-filter-list">
          {list.map(
            ({ isCurrent, label, value, name, isDisabled, tooltip }) => (
              <li
                className="grouped-search-page-section-filter-list__item"
                {...(tooltip
                  ? {
                      ref,
                      onMouseEnter: handleMouseEnter,
                      onMouseLeave: handleMouseLeave
                    }
                  : {})}
                key={label}
              >
                <button
                  className={cn(
                    'grouped-search-page-section-filter-list__button',
                    {
                      '-is-current': isCurrent,
                      '-lower-case': hasLowerCaseSectionFilters
                    }
                  )}
                  onClick={() =>
                    fetchResultsWithSection(
                      name,
                      !isCurrent ? value : undefined
                    )
                  }
                  disabled={isDisabled}
                  type="button"
                >
                  <span>{label}</span>
                </button>
                {tooltip && (showTabTooltip || showTooltip) && (
                  <Tooltip useTabStyle={showTabTooltip}>{tooltip}</Tooltip>
                )}
              </li>
            )
          )}
        </ul>
      )}
    </>
  );
};

GroupedSearchPageSectionFilterList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.exact({
      isCurrent: PropTypes.bool,
      label: PropTypes.string,
      value: PropTypes.string,
      name: PropTypes.string,
      isDisabled: PropTypes.bool,
      tooltip: PropTypes.string
    })
  ),
  fetchResultsWithSection: PropTypes.func,
  hasLowerCaseSectionFilters: PropTypes.bool
};

GroupedSearchPageSectionFilterList.propTypesMeta = 'exclude';

export default GroupedSearchPageSectionFilterList;
