/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'components-old/icon/icon';
import tableSort from 'js/utils/sort-table';

// Checks whether a string represents a number, to allow for special styling of numbers-as-text
const isNumber = (string = '') => !isNaN(Number(string.replace(/\s/g, '')));

const ThWithSort = ({ children, onClick, sortDirection }) => (
  <th
    className={cn({
      'table--hover-trigger': sortDirection === undefined,
      '-is-active': sortDirection !== undefined
    })}
    onClick={onClick}
    onKeyDown={e => e.key === 'Enter' && onClick()}
    type="button"
    tabIndex={0}
  >
    <div className="table--header-item">
      {children}
      <span>
        <Icon
          className={cn('table--sort-icon', {
            '-is-active': sortDirection
          })}
          name="sort-up"
          fill
        />
        <Icon
          className={cn('table--sort-icon', {
            '-is-active': sortDirection !== undefined && !sortDirection
          })}
          name="sort-down"
          fill
        />
      </span>
    </div>
  </th>
);

const Table = ({ header, rows, isSortingEnabled, sortingIndex, title }) => {
  const [rowsState, setRowsState] = useState(rows);
  const [sortDirection, setSortDirection] = useState(
    rows[0].map(() => undefined)
  );

  const sort = (index, initiallyReverse = false) => {
    setRowsState(rows => {
      const rowsCopy = rows.map(row => [...row]);
      rowsCopy.sort((a, b) => tableSort(a[index].value, b[index].value));

      const shouldReverse = sortDirection[index] || initiallyReverse;
      shouldReverse && rowsCopy.reverse();
      return rowsCopy;
    });

    setSortDirection(prevState =>
      prevState.map((shouldRev, i) =>
        i === index ? !(shouldRev || initiallyReverse) : undefined
      )
    );
  };

  useEffect(() => isSortingEnabled && sort(sortingIndex, true), []);

  return (
    <div className={cn('table', { 'table--print': isSortingEnabled })}>
      <table>
        <caption>{title}</caption>
        {header.length > 0 && (
          <thead>
            <tr>
              {header.map((column, index) => (
                <React.Fragment key={column + index}>
                  {isSortingEnabled ? (
                    <ThWithSort
                      onClick={() => sort(index)}
                      sortDirection={sortDirection[index]}
                    >
                      {column}
                    </ThWithSort>
                  ) : (
                    <th>{column}</th>
                  )}
                </React.Fragment>
              ))}
            </tr>
          </thead>
        )}
        {rowsState.length > 0 && (
          <tbody>
            {rowsState.map((row = [], index) => (
              <tr key={index + row[0].value}>
                {row.map(({ value, displayValue, isRightAligned }, index) => (
                  <td
                    className={cn({
                      '-is-number': isNumber(value),
                      '-is-right-aligned': isRightAligned
                    })}
                    key={value + index}
                  >
                    {displayValue || value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        )}
      </table>
    </div>
  );
};

Table.propTypes = {
  header: PropTypes.arrayOf(PropTypes.string),
  rows: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        displayValue: PropTypes.string,
        isRightAligned: PropTypes.bool
      })
    )
  ),
  isSortingEnabled: PropTypes.bool,
  sortingIndex: PropTypes.number,
  title: PropTypes.string
};

Table.propTypesMeta = 'exclude';

Table.defaultProps = {
  header: [],
  rows: [],
  sortingIndex: 0
};

export default Table;
