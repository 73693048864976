import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Image from 'components/image';

import propTypeTheme from 'utils/prop-type-theme';
import OPE from 'js/on-page-editing';

const themes = {
  noMargin: '-theme-no-margin'
};

const editorThemes = {
  halfWidth: '-theme-half-width'
};

const ImageWithCaption = ({
  caption,
  className,
  editorTheme,
  image,
  onPageEditing,
  theme,
  title,
  isExternal
}) => (
  <div
    className={cn(
      'image-with-caption',
      { 'image-with-caption--is-external': isExternal },
      theme,
      editorTheme,
      className
    )}
  >
    {title && <h2 {...OPE(onPageEditing.title)}>{title}</h2>}
    <Image {...image} />
    <div
      className="image-with-caption--caption"
      {...OPE(onPageEditing.caption)}
    >
      {caption}
    </div>
  </div>
);

ImageWithCaption.propTypes = {
  caption: PropTypes.string,
  className: PropTypes.string,
  editorTheme: PropTypes.oneOf(Object.values(editorThemes)),
  image: PropTypes.exact(Image.propTypes),
  onPageEditing: PropTypes.exact({
    caption: PropTypes.string,
    title: PropTypes.string
  }),
  theme: PropTypes.oneOf(Object.values(themes)),
  title: PropTypes.string,
  isExternal: PropTypes.bool
};

ImageWithCaption.defaultProps = {
  onPageEditing: {},
  isExternal: false
};

ImageWithCaption.propTypesMeta = 'exclude';

ImageWithCaption.themes = themes;

export default ImageWithCaption;
