import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Heading from 'components/heading';
import Metadata from 'components/metadata';
import Status from 'components/status/status';
import useIsScreenSize from 'js/hooks/use-is-screen-size';

const Vacancy = ({
  dateLabel,
  date,
  mobileDate,
  year,
  status,
  title,
  metadata,
  text,
  url,
  headingLevel
}) => {
  const isDesktop = useIsScreenSize('md');

  return (
    <div className="vacancy">
      <div className="vacancy--left">
        <div className={cn('vacancy--date', status && status.theme)}>
          <div className="vacancy--date-label">{dateLabel}</div>
          <div className="vacancy--date-date">
            {isDesktop ? date : mobileDate}
          </div>
          <div className="vacancy--date-year">{year}</div>
        </div>
      </div>
      <div className="vacancy--right">
        {status && <Status {...status} />}
        <a className="vacancy--link" href={url}>
          <Heading className="vacancy--title" level={headingLevel}>
            <span>{title}</span>
          </Heading>
        </a>
        {metadata && (
          <Metadata {...metadata} theme={Metadata.themes.verticalVacancies} />
        )}
        <p className="vacancy--text">{text}</p>
      </div>
    </div>
  );
};

Vacancy.propTypes = {
  id: PropTypes.string,
  dateLabel: PropTypes.string,
  date: PropTypes.string,
  mobileDate: PropTypes.string,
  year: PropTypes.string,
  metadata: PropTypes.exact(Metadata.propTypes),
  status: PropTypes.exact(Status.propTypes),
  text: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  headingLevel: PropTypes.number
};

Vacancy.propTypesMeta = 'exclude';

export default Vacancy;
