/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Button from 'components/button';
import Icon from 'components/icon';

import OPE from 'js/on-page-editing';
import Hyphenate from 'components/hyphenate';

import api from 'js/api-helper';
import doesContain from 'js/utils/doesContain';
import ArrowIconSrc from 'images/arrow-rounded.svg';
import FluidImage from 'components/fluid-image';

const themes = {
  actionLink: '-theme-action-link',
  extraIndentation: '-theme-extra-indentation',
  large: '-theme-large',
  previous: '-previous',
  next: '-next',
  navigationLink: 'navigation-link',
  navigationLinkNP: 'navigation-link-np',
  wrappedHeadingForPrint: 'wrapped-heading-for-print',

  //OLD:
  black: '-theme-black',
  blue: '-theme-blue',
  bold: '-theme-bold',
  fullWidth: '-theme-full-width',
  inlineIcon: '-theme-inline-icon',
  orangeIcon: '-theme-orange-icon',
  small: '-theme-small',
  tag: '-theme-tag',
  orangeTag: '-theme-orange-tag',
  underline: '-theme-underline',
  white: '-theme-white',
  portfolio: '-theme-portfolio'
};

const buttonThemes = {
  primary: '-theme-primary',
  secondary: '-theme-secondary',
  signal: '-theme-signal',
  dropdown: '-theme-dropdown',
  large: '-theme-large',
  small: '-theme-small',
  light: '-theme-light',

  //old:
  big: '-theme-big',
  link: '-theme-link',
  linkPrimary: '-theme-link-primary',
  linkSecondary: '-theme-link-secondary',
  outline: '-theme-outline',
  orangeOutline: '-theme-orange-outline',
  medium: '-theme-medium',
  uppercase: '-theme-uppercase',
  white: '-theme-white',
  smallMargin: 'theme-small-margin'
};

// NOTE: If link receives a Button theme, it inherits styles from the Button component instead of Link
const Link = ({
  attributes,
  children,
  className,
  icon,
  iconBeforeChildren,
  iconClassName,
  id,
  onClick,
  onPageEditing,
  text,
  theme,
  url,
  trackUrl,
  useButtonStyles,
  label,
  noHoverEffect,
  openInNewWindow,
  isExternal,
  noFollow,
  useSkinnyIcon
}) => {
  const isActionLink = doesContain(theme, themes.actionLink);
  const handleOnClick = e => {
    if (trackUrl) {
      var defaultBehaviour = e.ctrlKey;
      if (!defaultBehaviour) {
        e.preventDefault();
      }
      api.execute(trackUrl, {}).then(_response => {
        if (!defaultBehaviour && url) {
          window.location.href = url;
        }
      });
    }
  };

  const Element = url ? 'a' : 'span';
  const baseClassName = useButtonStyles ? Button.className : 'link';

  const ExternalLinkContent =
    isExternal &&
    url &&
    externalLinkHelper(
      text,
      children,
      iconClassName,
      iconBeforeChildren,
      icon,
      useSkinnyIcon,
      isActionLink
    );

  const newWindowProps = openInNewWindow
    ? { target: '_blank', rel: 'noopener norefferer' }
    : {};
  return (
    <Element
      className={cn(baseClassName, className, theme, {
        '-has-children': !!text || !!children,
        '-has-icon-left': iconBeforeChildren,
        '-has-icon-right': icon,
        '-has-hover-effect': !noHoverEffect,
        '-is-external-link': !!ExternalLinkContent
      })}
      id={id}
      href={url || null} // The 'null' makes sure that the 'href' attribute is not written to the DOM when 'url' is an empty string
      onClick={onClick || handleOnClick}
      role={onClick ? 'button' : null}
      aria-label={label}
      {...newWindowProps}
      {...attributes}
      {...OPE(onPageEditing)}
      rel={noFollow && 'nofollow'}
    >
      {ExternalLinkContent ? (
        <ExternalLinkContent />
      ) : (
        <>
          {iconBeforeChildren && (
            <Icon className={iconClassName} name={iconBeforeChildren} />
          )}
          {isActionLink && (
            <div className="action-link-icon">
              <FluidImage src={ArrowIconSrc} alt="ikon" />
            </div>
          )}
          {children}
          {text && <span className="link--text">{split(text)}</span>}
          {icon && <Icon className={iconClassName} name={icon} />}
        </>
      )}
    </Element>
  );
};

Link.propTypes = {
  attributes: PropTypes.shape(typeof Object),
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.string,
  iconBeforeChildren: PropTypes.string,
  iconClassName: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  onPageEditing: PropTypes.string,
  text: PropTypes.string,
  // eslint-disable-next-line @creuna/prop-types-csharp/all
  theme: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  url: PropTypes.string,
  trackUrl: PropTypes.string,
  useButtonStyles: PropTypes.bool,
  label: PropTypes.string,
  noHoverEffect: PropTypes.bool,
  isExternal: PropTypes.bool,
  openInNewWindow: PropTypes.bool,
  isPrimary: PropTypes.bool, // This is only to add it to the CS model
  noFollow: PropTypes.bool,
  useSkinnyIcon: PropTypes.bool
};

Link.propTypesMeta = 'exclude';

Link.themes = themes;

export default Link;

function split(str) {
  if (typeof str === 'string' && str.includes('///'))
    return (
      <span>
        {str.split('///').map(line => (
          <span className="link-line" key={line}>
            {line}
          </span>
        ))}
      </span>
    );
  return <Hyphenate text={str} />;
}

function externalLinkHelper(
  text,
  children,
  iconClassName,
  iconBeforeChildren,
  icon,
  useSkinnyIcon,
  isActionLink
) {
  const TextAndIcon = () => {
    const withoutLastWord =
      text &&
      text
        .split(' ')
        .slice(0, text.split(' ').length - 1)
        .join(' ');
    const lastWord = text ? ` ${text.split(' ').pop()}` : '';

    return (
      <>
        {children}
        <span className="link--text">
          {split(withoutLastWord)}
          <span style={{ whiteSpace: 'nowrap' }}>
            {lastWord}
            <Icon
              {...(useSkinnyIcon
                ? {
                    name: 'external-link-arrow-skinny',
                    className: 'external-link-icon-skinny'
                  }
                : {
                    name: 'external-link-arrow',
                    className: 'external-link-icon'
                  })}
            />
          </span>
        </span>
      </>
    );
  };

  const ExternalLinkContent = () => (
    <span>
      {iconBeforeChildren && (
        <Icon className={iconClassName} name={iconBeforeChildren} />
      )}
      {isActionLink && (
        <div className="action-link-icon">
          <FluidImage src={ArrowIconSrc} alt="ikon" />
        </div>
      )}
      <TextAndIcon />
      {icon && <Icon className={iconClassName} name={icon} />}
    </span>
  );

  return ExternalLinkContent;
}
