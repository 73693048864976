import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import OPE from 'js/on-page-editing';

import Button from 'components/button';
import Image from 'components/image';
import Link from 'components/link';
import ContentContainer from 'components/content-container';

import hyphenate from 'js/utils/hyphenate';

const styleProps = (hasSingleLink, index) => {
  if (hasSingleLink)
    return { theme: Link.themes.actionLink, useButtonStyles: false };
  if (index === 0)
    return { theme: Button.themes.primary, useButtonStyles: true };
  if (index === 1)
    return { theme: Button.themes.secondary, useButtonStyles: true };
};

const PortfolioHeader = ({
  image,
  title,
  description,
  links,
  onPageEditing = {},
  isPageHeader
}) => {
  return (
    <div className={cn('portfolio-header', { '-page-header': isPageHeader })}>
      <ContentContainer theme={ContentContainer.themes.wide}>
        <div className="portfolio-header--content">
          <div className="portfolio-header--left">
            <div className="portfolio-header--text-container">
              {title && !isPageHeader && (
                <h2 className="portfolio-header--title">{title}</h2>
              )}
              {title && isPageHeader && (
                <h1
                  className="portfolio-header--title portfolio-header--page-header-title"
                  {...hyphenate(title)}
                ></h1>
              )}
              {description && (
                <p className="portfolio-header--description">{description}</p>
              )}
            </div>
            <div
              className="portfolio-header--links"
              {...OPE(onPageEditing.links)}
            >
              {links &&
                links.map((link, index) => (
                  <Link
                    className="portfolio-header--link"
                    key={`${link.url}-${index}`}
                    {...styleProps(links.length === 1, index)}
                    {...link}
                  />
                ))}
            </div>
          </div>
          <div className="portfolio-header--image-padding">
            <div className="portfolio-header--image">
              <Image {...image} />
            </div>
          </div>
        </div>
      </ContentContainer>
    </div>
  );
};

PortfolioHeader.propTypes = {
  image: PropTypes.exact(Image.propTypes),
  links: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  onPageEditing: PropTypes.exact({
    links: PropTypes.string,
    numbers: PropTypes.string,
    text: PropTypes.string
  }),
  title: PropTypes.string,
  description: PropTypes.string,
  isPageHeader: PropTypes.bool
};

PortfolioHeader.propTypesMeta = 'exclude';

export default PortfolioHeader;
