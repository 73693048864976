import React from 'react';
import PropTypes from 'prop-types';

import EmptyList from 'components/empty-list';
import FilterLayout from 'components/filter-layout';
import Form from 'components/form';
import ProposalGroup from './proposal-group';
import TopFilter from 'components/top-filter';
import TabList from 'components/tabs/tab-list';
import Link from 'components/link';
import Heading from 'components/heading';
import Spinner from 'components/spinner';
import useFetchFilteredResults from 'js/hooks/use-fetch-filtered-results';
import ProposalShortcuts from 'components/proposal-shortcuts/proposal-shortcuts';
import ContentContainer from 'components/content-container/content-container';

const ProposalListPage = ({
  emptyList,
  filterLayout,
  form = {},
  groups,
  title,
  sectionTitle,
  topFilter = {},
  fetchFilteredResultsEndpoint,
  proposalShortcuts
}) => {
  const [
    isLoading,
    {
      emptyList: emptyListState,
      filterLayout: filterLayoutState,
      groups: groupsState
    },
    fetchResults
  ] = useFetchFilteredResults(
    { emptyList, filterLayout, groups },
    fetchFilteredResultsEndpoint,
    form.endpoint,
    topFilter.timeframe
  );

  return (
    <Form className="proposal-list-page" showSubmitButton={false} {...form}>
      <ContentContainer className="proposal-list-page--top">
        {title && <h1 className="proposal-list-page--title">{title}</h1>}
        {proposalShortcuts && <ProposalShortcuts {...proposalShortcuts} />}
      </ContentContainer>
      <FilterLayout
        className="proposal-list-page--hide-underline-overflow"
        {...filterLayoutState}
        includeBottomBorder={false}
        fetchFilteredResults={fetchResults}
        topContent={
          topFilter && topFilter.options ? (
            <TabList className="proposal-list-page--tab-list">
              {topFilter.options.map(({ isCurrent, link, disabled }, index) => (
                <TabList.Item
                  key={link.url}
                  isCurrent={isCurrent}
                  length={topFilter.options.length}
                  index={index}
                  currentIndex={topFilter.options.findIndex(
                    ({ isCurrent }) => isCurrent
                  )}
                  disabled={disabled}
                >
                  <Link theme={isCurrent ? Link.themes.black : []} {...link} />
                </TabList.Item>
              ))}
            </TabList>
          ) : null
        }
        isLoading={isLoading}
      >
        <React.Fragment>
          {sectionTitle && <Heading>{sectionTitle}</Heading>}
          {groupsState && groupsState.length > 0 ? (
            groupsState.map((group, index) => (
              <ProposalGroup
                headingLevel={sectionTitle ? 3 : 2}
                key={group.title + String(index)}
                {...group}
              />
            ))
          ) : (
            <EmptyList {...emptyListState} />
          )}
          <Spinner isActive={isLoading} />
        </React.Fragment>
      </FilterLayout>
      {topFilter.timeframe && (
        <input
          type="hidden"
          id={topFilter.timeframe.id}
          name={topFilter.timeframe.name}
          value={topFilter.timeframe.value}
        />
      )}
    </Form>
  );
};

ProposalListPage.propTypes = {
  emptyList: PropTypes.exact(EmptyList.propTypes),
  filterLayout: PropTypes.exact(FilterLayout.propTypes),
  form: PropTypes.exact(Form.propTypes),
  groups: PropTypes.arrayOf(PropTypes.exact(ProposalGroup.propTypes)),
  title: PropTypes.string,
  topFilter: PropTypes.exact(TopFilter.propTypes),
  sectionTitle: PropTypes.string,
  fetchFilteredResultsEndpoint: PropTypes.string,
  proposalShortcuts: PropTypes.exact(ProposalShortcuts.propTypes),
  description: PropTypes.string
};

ProposalListPage.defaultProps = {
  groups: []
};

export default ProposalListPage;
