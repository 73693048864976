import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Button from 'components/button';
import ContentContainer from 'components/content-container';
import GlobalSearch from './global-search';
import Link from 'components/link';
import Menu from 'components/menu';
import TabMenu from 'components/tab-menu';
import useOutsideClick from 'js/hooks/use-outside-click';
import SimpleHeader from './simple-header';

const Header = ({
  globalSearch,
  linkToHome,
  linkList,
  logo,
  menu,
  menuText,
  tabMenu,
  priorityLink,
  simpleHeader
}) => {
  const [globalSearchIsOpen, setGlobalSearchIsOpen] = useState();
  const [isMounted, setIsMounted] = useState();
  const [menuIsOpen, setMenuIsOpen] = useState();

  const containerRef = useRef();

  const openGlobalSearch = e => {
    e.preventDefault();
    setGlobalSearchIsOpen(true);
    setMenuIsOpen(false);
  };

  const closeGlobalSearch = () => {
    setGlobalSearchIsOpen(false);
  };

  const openMenu = () => {
    document.body.style.position = 'fixed';
    setMenuIsOpen(true);
    setGlobalSearchIsOpen(false);
  };

  const closeMenu = () => {
    document.body.style.position = '';
    setMenuIsOpen(false);
  };

  useOutsideClick(containerRef, closeGlobalSearch);

  useEffect(() => setIsMounted(true));

  const menuId = 'main-menu';
  const menuLinkList = priorityLink ? [priorityLink, ...linkList] : linkList;
  return (
    <React.Fragment>
      <TabMenu openMenu={openMenu} {...tabMenu} />
      {simpleHeader && <SimpleHeader {...simpleHeader} />}
      <header className="header" ref={containerRef}>
        <ContentContainer
          element="nav"
          className="header--content"
          theme={ContentContainer.themes.wide}
        >
          <a href={linkToHome.url} className="header--logo">
            <span>{linkToHome.text}</span>
            <img alt="Logo" src={logo} />
          </a>
          <ul
            className={cn('header--nav', {
              '-menu-is-open': menuIsOpen
            })}
          >
            {linkList.map(link => (
              <li key={link.url} className="header--link">
                <Link openInNewWindow={link.isExternal} {...link} />
              </li>
            ))}
            {priorityLink && (
              <li
                key={priorityLink.url}
                className="header--link -show-on-mobile"
              >
                <Link
                  openInNewWindow={priorityLink.isExternal}
                  {...priorityLink}
                />
              </li>
            )}
            <li className="header--search">
              {globalSearch && globalSearch.input && (
                <GlobalSearch
                  closeGlobalSearch={closeGlobalSearch}
                  isOpen={globalSearchIsOpen}
                  openGlobalSearch={openGlobalSearch}
                  {...globalSearch}
                />
              )}
            </li>
            <li className="header--menu">
              <Button
                className="header--menu-button"
                theme={[Button.themes.primary]}
                onClick={openMenu}
                attributes={{
                  'aria-controls': isMounted ? menuId : null,
                  'aria-expanded': menuIsOpen
                }}
              >
                {menuText}
              </Button>
            </li>
          </ul>

          <Menu
            closeMenu={closeMenu}
            globalSearch={globalSearch}
            id={menuId}
            isOpen={menuIsOpen}
            headerLinkList={menuLinkList}
            {...menu}
          />
        </ContentContainer>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  globalSearch: PropTypes.exact(GlobalSearch.propTypes).isRequired,
  linkToHome: PropTypes.exact(Link.propTypes),
  linkList: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)).isRequired,
  logo: PropTypes.string,
  menu: PropTypes.exact(Menu.propTypes),
  menuText: PropTypes.string.isRequired,
  tabMenu: PropTypes.exact(TabMenu.propTypes),
  priorityLink: PropTypes.exact(Link.propTypes),
  simpleHeader: PropTypes.exact(SimpleHeader.propTypes)
};

Header.defaultProps = {
  linkList: [],
  linkToHome: {},
  logo: require('../../static-site/assets/logo.svg').default
};

export default Header;
