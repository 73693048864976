import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/button';
import Icon from 'components/icon';
import Checkbox from 'components/form-elements/checkbox';
import FilterGroup from './filter-group';
import RangeSlider from 'components/range-slider';
import useIsScreenSize from 'js/hooks/use-is-screen-size';
import Spinner from 'components/spinner';

const Filters = ({
  checkboxes,
  items,
  labels,
  rangeFilter,
  fetchFilteredResults,
  title,
  close,
  mobileTitle,
  isLoading,
  dataId,
  updateFilterQuery
}) => {
  const [isResetting, setIsResetting] = useState();
  const [isMounted, setIsMounted] = useState();
  const [searchQueryParameter, setSearchQueryParameter] = useState({
    ...getInitialSearchQueryParameter(items),
    ...getInitialSearchQueryParameter([{ options: checkboxes }])
  });
  const isDesktop = useIsScreenSize('lg');

  useEffect(() => {
    if (updateFilterQuery) updateFilterQuery(searchQueryParameter);
  }, [searchQueryParameter]);

  const setSearchQueryParameterHelper = e => {
    const { name, value, checked } = e.target;
    setSearchQueryParameter(prevState => ({
      ...prevState,
      [name]: checked
        ? prevState[name].concat(value)
        : prevState[name].filter(item => item !== value)
    }));
  };

  function getInitialSearchQueryParameter(items) {
    let result = {};
    items.map(item => {
      item.options.map(option => {
        let target = option.name;
        if (!(target in result)) {
          result[target] = [];
        }
        if (option.checked) {
          result[target] = result[target].concat(option.value);
        }
      });
    });
    return result;
  }

  useEffect(() => {
    isMounted && fetchFilteredResults(searchQueryParameter);
  }, [searchQueryParameter]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    !isLoading && isResetting && setIsResetting(false);
  }, [isLoading]);

  const deselectItems = () => {
    setIsResetting(true);
    setSearchQueryParameter(state =>
      Object.keys(state).reduce(
        (acc, key) => ({
          ...acc,
          [key]: []
        }),
        {}
      )
    );
  };

  return (
    <div id="filters" className="filters">
      {!isDesktop ? (
        <div className="filters--mobile-header">
          <span className="filters--mobile-title">{mobileTitle}</span>
          <button
            className="filters--mobile-close"
            onClick={() => close()}
            type="button"
          >
            <span className="filters--mobile-close-text">
              {labels.closeDialog}
            </span>
            <span aria-hidden="true" className="filters--mobile-close-icon">
              <Icon name="x-rounded" />
            </span>
          </button>
        </div>
      ) : (
        title && <div className="filters--title">{title}</div>
      )}
      <div className="filters--filters">
        {items &&
          items.map(item => (
            <FilterGroup
              {...item}
              key={item.title}
              updateFilterOptions={setSearchQueryParameterHelper}
              isDesktop={isDesktop}
              dataId={dataId}
            />
          ))}
        {checkboxes &&
          checkboxes.map(checkbox => (
            <Checkbox
              className="filters--checkbox"
              key={checkbox.name}
              onChange={setSearchQueryParameterHelper}
              theme={Checkbox.themes.blue}
              dataId={dataId}
              {...checkbox}
            />
          ))}
        <button
          type="button"
          className="filters--deselect-button"
          onClick={deselectItems}
        >
          {labels.reset}
        </button>
        {rangeFilter && (
          <RangeSlider className="filters--range" {...rangeFilter} />
        )}
      </div>
      {!isDesktop && labels.showResults && (
        <div className="filters--show-results">
          <Button
            onClick={close}
            theme={[Button.themes.primary, Button.themes.large]}
          >
            {labels.showResults}
          </Button>
        </div>
      )}

      <Spinner isActive={isResetting} />
    </div>
  );
};

Filters.propTypes = {
  checkboxes: PropTypes.arrayOf(PropTypes.exact(Checkbox.propTypes)),
  items: PropTypes.arrayOf(PropTypes.shape(FilterGroup.propTypes)),
  labels: PropTypes.exact({
    showResults: PropTypes.string,
    reset: PropTypes.string,
    closeDialog: PropTypes.string
  }),
  rangeFilter: PropTypes.exact(RangeSlider.propTypes),
  fetchFilteredResults: PropTypes.func,
  title: PropTypes.string,
  close: PropTypes.func,
  mobileTitle: PropTypes.string,
  isLoading: PropTypes.bool,
  dataId: PropTypes.number,
  updateFilterQuery: PropTypes.func
};

Filters.defaultProps = {
  checkboxes: [],
  items: [],
  labels: {}
};

Filters.propTypesMeta = 'exclude';

export default Filters;
