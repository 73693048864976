import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import propTypeTheme from 'utils/prop-type-theme';

import DateCardMedia from './date-card-media';
import DateCardStatus from './date-card-status';
import DateCardTags from './date-card-tags';
import DateCardDates from './date-card-dates';
import Heading from 'components-old/heading';
import Link from 'components-old/link';
import Metadata from 'components-old/metadata';
import Message from 'components-old/message';
import resizeHook from 'js/hooks/resize';

const themes = {
  rightAlignedTags: '-theme-right-aligned-tags',
  noPadding: '-theme-no-padding',
  noBorder: '-theme-no-border',
  sidebar: '-theme-sidebar',
  vertical: '-theme-vertical'
};

const useGetHeightOfName = ref => {
  const [nameHeight, setNameHeight] = useState();

  useEffect(() => {
    if (!ref.current) return;
    const nameHeight = ref.current.offsetHeight;
    setNameHeight(nameHeight);
  });

  resizeHook(() => setNameHeight(ref.current && ref.current.offsetHeight));

  return nameHeight;
};

const DateCard = ({
  dateContainer,
  headingLevel,
  media,
  metadata,
  metadataTheme,
  messageList,
  showMedia,
  subtitle,
  status,
  tags,
  theme,
  title,
  text,
  url,
  usedInSidebar,
  isImageLink,
  isEventList,
  name
}) => {
  const { dates = [] } = dateContainer;
  const hasDate = dates.length || dateContainer.date;

  const nameRef = useRef();
  const heightOfName = useGetHeightOfName(nameRef);

  return (
    <div
      className={cn('date-card', theme, {
        '-without-date': !hasDate
      })}
    >
      {name && (
        <Heading ref={nameRef} className="date-card--name">
          {name}
        </Heading>
      )}
      <div
        className={cn('date-card__grid date-card__grid-primary', {
          '-is-past-date': dateContainer.isPastDate,
          '-is-planned': dateContainer.isPlanned,
          '-without-date': !hasDate
        })}
      >
        <Link className="date-card--title" url={url}>
          {title && (
            <Heading className="date-card--heading" level={headingLevel}>
              {title}
            </Heading>
          )}
          {subtitle && (
            <Heading
              className="date-card--sub-heading"
              level={headingLevel + 1}
            >
              {subtitle}
            </Heading>
          )}
        </Link>

        {hasDate && (
          <DateCardDates
            {...dateContainer}
            theme={
              theme && theme.indexOf('-theme-vertical') !== -1
                ? DateCardDates.themes.big
                : undefined
            }
            url={isImageLink && url}
            isEventList={isEventList}
            heightOfName={heightOfName}
          />
        )}
        <div className="date-card--content">
          {messageList.map(message => (
            <div key={message.text.text} className="date-card--message">
              <Message {...message}></Message>
            </div>
          ))}
          {metadata && (
            <div className="date-card--metadata">
              <Metadata
                theme={usedInSidebar ? Metadata.themes.vertical : metadataTheme}
                {...metadata}
              />
            </div>
          )}
          {showMedia && media && <DateCardMedia {...media} />}
          {text && <p className="date-card--text">{text}</p>}
        </div>
      </div>
      {(status || tags) && (
        <div className="date-card__grid date-card__grid-secondary">
          {status && (
            <DateCardStatus
              centered={!usedInSidebar}
              theme={
                (dateContainer.isPlanned && DateCardStatus.themes.isPlanned) ||
                (dateContainer.isPastDate && DateCardStatus.themes.isCompleted)
              }
              {...status}
            />
          )}

          {tags && <DateCardTags {...tags} />}
        </div>
      )}
    </div>
  );
};

DateCard.propTypes = {
  dateContainer: PropTypes.exact(DateCardDates.propTypes),
  messageList: PropTypes.arrayOf(PropTypes.exact(Message.propTypes)),
  headingLevel: PropTypes.number,
  metadata: PropTypes.exact(Metadata.propTypes),
  metadataTheme: PropTypes.string,
  // Disable eslint warning of unused propType because 'id' is used as 'key' when rendering lists of DateCard
  // eslint-disable-next-line
  id: PropTypes.string.isRequired,
  media: PropTypes.exact(DateCardMedia.propTypes),
  showMedia: PropTypes.bool,
  status: PropTypes.exact(DateCardStatus.propTypes),
  subtitle: PropTypes.string,
  tags: PropTypes.exact(DateCardTags.propTypes),
  text: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes)),
  title: PropTypes.string,
  url: PropTypes.string,
  usedInSidebar: PropTypes.bool,
  isImageLink: PropTypes.bool,
  isEventList: PropTypes.bool,
  name: PropTypes.string
};

DateCard.propTypesMeta = 'exclude';

DateCard.defaultProps = {
  headingLevel: 2,
  messageList: [],
  showMedia: true,
  dateContainer: {}
};

DateCard.themes = themes;

export default DateCard;
