import React from 'react';
import PropTypes from 'prop-types';

import 'intersection-observer';
import cn from 'classnames';
import debounce from 'lodash/debounce';

import responsiveValue from 'js/utils/responsive-value';

import Button from '../button';
import Carousel from '../carousel';
import ContentContainer from '../content-container';
import RichText from '../rich-text';
import TimelineItem from './timeline-item';
import Icon from 'components-old/icon';

const themes = {
  block: '-theme-block'
};

class TimelineHorizontal extends React.Component {
  static propTypes = {
    labels: PropTypes.exact({
      next: PropTypes.string,
      previous: PropTypes.string
    }),
    numberOfSlidesToShow: PropTypes.shape(typeof Object),
    items: PropTypes.arrayOf(PropTypes.exact(TimelineItem.propTypes)),
    startIndex: PropTypes.number,
    title: PropTypes.string,
    theme: PropTypes.oneOf(Object.values(themes))
  };

  static propTypesMeta = 'exclude';

  static defaultProps = {
    labels: {},
    numberOfSlidesToShow: {
      '0px': 1,
      '510px': 2,
      '768px': 3,
      '1024px': 4
    },

    items: []
  };

  state = {
    isMounted: false,
    numberOfSlidesToShow: 1,
    isVisible: false
  };

  ref = React.createRef();

  setNumberOfSlidesToShow = debounce(
    () => {
      const numberOfSlidesToShow = responsiveValue(
        this.props.numberOfSlidesToShow
      );

      this.setState({ numberOfSlidesToShow });
    },
    200,
    { leading: true }
  );

  componentDidMount() {
    this.setNumberOfSlidesToShow();
    this.setState({ isMounted: true });
    window.addEventListener('resize', this.setNumberOfSlidesToShow);
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          this.setState({ isVisible: true });
          observer.disconnect();
        }
      },
      { root: null, rootMargin: '0px', threshold: 0.5 }
    );

    if (this.ref.current) {
      observer.observe(this.ref.current);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setNumberOfSlidesToShow);
  }

  render() {
    const { labels, theme } = this.props;

    return (
      <React.Fragment>
        <ContentContainer className="timeline-horizontal-print">
          {this.props.title && <h2>{this.props.title}</h2>}
          {this.props.items.map(item => (
            <div
              className="timeline-horizontal-print--item"
              key={item.title || item.subTitle}
            >
              {item.title && (
                <h3 className="timeline-horizontal-print--title">
                  {item.title}
                </h3>
              )}

              {item.subTitle && (
                <h4 className="timeline-horizontal-print--sub-title">
                  {item.subTitle}
                </h4>
              )}

              <RichText {...item.text} />
            </div>
          ))}
        </ContentContainer>
        <div
          ref={this.ref}
          className={cn('timeline-horizontal', theme, {
            '-is-mounted': this.state.isMounted
          })}
        >
          <ContentContainer theme={ContentContainer.themes.wide}>
            {this.props.title && <h2>{this.props.title}</h2>}
          </ContentContainer>
          <ContentContainer
            className="timeline-horizontal--content"
            theme={ContentContainer.themes.wide}
          >
            <div className="timeline-horizontal--track">
              {this.state.isVisible && (
                <Carousel
                  numberOfSlidesToShow={this.state.numberOfSlidesToShow}
                  startIndex={this.props.startIndex}
                  nextButton={(next, hasNext) => (
                    <Button
                      className="timeline-horizontal--next"
                      disabled={!hasNext}
                      onClick={next}
                      title={labels.next}
                    >
                      <Icon
                        className="timeline-horizontal--next-icon"
                        fill
                        name="open-arrow-circle"
                      />
                    </Button>
                  )}
                  previousButton={(previous, hasPrevious) => (
                    <Button
                      className="timeline-horizontal--previous"
                      disabled={!hasPrevious}
                      onClick={previous}
                      title={labels.previous}
                    >
                      <Icon
                        className="timeline-horizontal--previous-icon"
                        fill
                        name="open-arrow-circle"
                      />
                    </Button>
                  )}
                >
                  {this.props.items.map((item, index) => {
                    const delay = 500 * (index - this.props.startIndex + 1);
                    return (
                      <TimelineItem
                        key={item.title + item.subTitle}
                        delay={delay}
                        isLast={index === this.props.items.length - 1}
                        {...item}
                      />
                    );
                  })}
                </Carousel>
              )}
            </div>
          </ContentContainer>
        </div>
      </React.Fragment>
    );
  }
}

TimelineHorizontal.themes = themes;

export default TimelineHorizontal;
