import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Heading from 'components-old/heading';
import RichText from 'components-old/rich-text';

const themes = {
  narrow: '-theme-narrow'
};

// NOTE: headingLevelOffset is intended to be used by the backend only, as a way to describe the semantic relationships between blocks without having to know what the actual heading levels are.
const RichTextBlock = ({
  headingLevel,
  headingLevelOffset,
  htmlId,
  text,
  theme,
  title
}) => (
  <div className={cn('rich-text-block', theme)}>
    {title && (
      <div data-epi-type="title">
        <Heading
          id={htmlId}
          level={headingLevel}
          levelOffset={headingLevelOffset}
        >
          {title}
        </Heading>
      </div>
    )}
    <div data-epi-type="content">
      <RichText {...text} />
    </div>
  </div>
);

RichTextBlock.propTypes = {
  headingLevel: PropTypes.number,
  headingLevelOffset: PropTypes.number,
  htmlId: PropTypes.string,
  text: PropTypes.exact(RichText.propTypes),
  theme: PropTypes.oneOf(Object.values(themes)),
  title: PropTypes.string
};

RichTextBlock.propTypesMeta = 'exclude';

RichTextBlock.themes = themes;

export default RichTextBlock;
