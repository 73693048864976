import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import responsiveValue from 'js/utils/responsive-value';
import resizeHook from 'js/hooks/resize';

import Carousel from 'components/carousel';
import Button from 'components/button';
import ProcessItem from './process-item';
import HeightOfChild from 'components/height-of-child';

// Keeping breakpoints outside of component should be fine
// because this state applies to all ProcessCarousels on a page.
let breakpoints = {
  '0px': 1,
  '510px': 2,
  '768px': 3
};

const ProcessCarousel = ({ items, labels = {} }) => {
  const [numberOfSlidesToShow, setNumberOfSlidesToShow] = useState(3);

  const updateNumberOfSlides = () => {
    const slidesToShow = Math.min(items.length, responsiveValue(breakpoints));
    setNumberOfSlidesToShow(slidesToShow);
  };

  resizeHook(updateNumberOfSlides);

  return (
    <HeightOfChild className="process-carousel">
      {Child => (
        <Carousel
          numberOfSlidesToShow={numberOfSlidesToShow}
          nextButton={(next, hasNext) => (
            <Button
              className="process-carousel__button process-carousel__button-next"
              disabled={!hasNext}
              icon="arrow-rounded"
              onClick={next}
              title={labels.next}
            />
          )}
          previousButton={(prev, hasPrev) => (
            <Button
              className="process-carousel__button process-carousel__button-prev"
              disabled={!hasPrev}
              icon="arrow-rounded"
              onClick={prev}
              title={labels.previous}
            />
          )}
        >
          {items.map((item, index) => (
            <Child
              className="process-carousel--item"
              key={item.title || item.text || index}
            >
              <ProcessItem key={item.text} {...item} index={index} />
            </Child>
          ))}
        </Carousel>
      )}
    </HeightOfChild>
  );
};

ProcessCarousel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.exact(ProcessItem.propTypes)),
  hasSideBar: PropTypes.bool,
  labels: PropTypes.exact({
    next: PropTypes.string,
    previous: PropTypes.string
  })
};

ProcessCarousel.propTypesMeta = 'exclude';

export default ProcessCarousel;
