import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components-old/icon';
import cn from 'classnames';

const themes = {
  red: '-theme-red',
  blue: '-theme-blue',
  gray: '-theme-gray'
};

const sizes = {
  wide: '-size-wide',
  small: '-size-small'
};

const IconWarning = ({ theme, size, isColored }) => {
  const iconName = () => {
    let name = () => {
      switch (theme) {
        case themes.blue:
          return 'info';
        case themes.red:
          return 'warning';
        default:
          return 'exclamation-mark';
      }
    };
    const color = isColored ? '-color' : '';
    return `${name()}${color}`;
  };

  return (
    <Icon fill className={cn('icon-warning', size, theme)} name={iconName()} />
  );
};
IconWarning.propTypes = {
  theme: PropTypes.oneOf(Object.values(themes)),
  size: PropTypes.oneOf(Object.values(sizes)),
  isColored: PropTypes.bool
};

IconWarning.propTypesMeta = 'exclude';

IconWarning.themes = themes;
IconWarning.sizes = sizes;

export default IconWarning;
