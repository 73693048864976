import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Button from 'components/button';
import Image from 'components/image';
import Shape from 'components/nysgjerrigper/shape/shape';
import { getColor } from 'js/utils/np-colors';
import doAndWait from 'js/utils/do-and-wait';

const StepsBlock = ({ pages, stepIndex }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animateLeft, setAnimateLeft] = useState(false);
  const [removeTransition, setRemoveTransition] = useState(false);

  const nextPage = async () => {
    if (animateLeft || removeTransition) return;
    await doAndWait(() => setAnimateLeft(true), 500);

    // These setState calls don't get batched ; they rerender after each call because nextPage is async.
    // https://stackoverflow.com/questions/53048495/does-react-batch-state-update-functions-when-using-hooks
    requestAnimationFrame(() => {
      // Jump to the right
      setCurrentIndex(i => (i + 1) % pages.length);
      setRemoveTransition(true);
      setAnimateLeft(false);
      // Add transition after the jump is completed
      requestAnimationFrame(() => setRemoveTransition(false));
    });
  };

  const currentAndNextPage = [
    pages[currentIndex],
    pages[(currentIndex + 1) % pages.length]
  ].filter(page => !!page);
  return (
    <>
      <Shape
        className="steps-block--snake"
        type={Shape.types.snakeH}
        color={getColor(stepIndex)}
      />
      <div className="steps-block-list">
        {currentAndNextPage.map(({ title, text, buttonText, image }, index) => {
          const stepIndex = ((index + currentIndex) % pages.length) + 1;
          return (
            <div
              className={cn('steps-block', {
                '-animate-left': animateLeft,
                '-remove-transition': removeTransition
              })}
              key={title}
            >
              <div className="steps-block--content">
                <h2 className="steps-block--content-title">
                  <span className="steps-block--content-page-number">{`${stepIndex}/${pages.length}`}</span>
                  {title}
                </h2>
                <p className="steps-block--content-paragraph">{text}</p>
                <div className="steps-block--content-button-container">
                  <Button
                    className="steps-block--content-button"
                    theme={[Button.themes.primary, Button.themes.big]}
                    onClick={nextPage}
                    disabled={stepIndex !== currentIndex + 1}
                  >
                    {buttonText}
                  </Button>
                </div>
              </div>
              <div className="steps-block--image">
                {/* "Responsive" adds image size queries which prevents GIFs from animating */}
                <Image {...image} responsive={false} />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

StepsBlock.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.exact({
      title: PropTypes.string,
      text: PropTypes.string,
      buttonText: PropTypes.string,
      image: PropTypes.exact(Image.propTypes)
    })
  ),
  stepIndex: PropTypes.number
};
StepsBlock.propTypesMeta = 'exclude';
export default StepsBlock;
