import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Button from 'components/button';
import Link from 'components/link';
import MenuSearch from './menu-search';
import Modal from 'components/modal';
import NavigationGroup from './navigation-group';

const Menu = ({
  closeMenu,
  closeButtonText,
  globalSearch,
  id,
  isOpen,
  headerLinkList,
  navigationGroups
}) => {
  return (
    <Modal
      className="menu--wrapper"
      hideModal={closeMenu}
      id={id}
      isVisible={isOpen}
    >
      <div className={cn('menu', { '-is-open': isOpen })}>
        <div className="menu--content">
          <div className="menu--header">
            <Button
              className="menu--close"
              iconBeforeChildren="x"
              onClick={closeMenu}
              title={closeButtonText}
              attributes={{
                'aria-controls': id,
                'aria-expanded': isOpen
              }}
            />
          </div>
          {globalSearch && globalSearch.input && (
            <MenuSearch {...globalSearch} />
          )}
          <nav>
            {headerLinkList && (
              <NavigationGroup
                className="menu--header-link-list"
                items={headerLinkList.map(link => ({
                  link: {
                    ...link,
                    ...(link.isExternal
                      ? { useSkinnyIcon: true }
                      : { icon: 'change' })
                  }
                }))}
              />
            )}
            {navigationGroups.map((group, index) => (
              <NavigationGroup key={group.title + String(index)} {...group} />
            ))}
          </nav>
        </div>
      </div>
    </Modal>
  );
};

Menu.propTypes = {
  closeMenu: PropTypes.func,
  closeButtonText: PropTypes.string,
  globalSearch: PropTypes.shape(MenuSearch.propTypes), // Intentional use of 'shape' since 'GlobalSearch' has properties that aren't used by 'MenuSearch'
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  headerLinkList: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  navigationGroups: PropTypes.arrayOf(
    PropTypes.exact(NavigationGroup.propTypes)
  )
};

Menu.propTypesMeta = 'exclude';

Menu.defaultProps = {
  linkList: [],
  navigationGroups: []
};

export default Menu;
