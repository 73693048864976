import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Icon from 'components/icon/icon';
import { backgrounds } from 'js/utils/np-colors.js';

const Step = ({
  text,
  index,
  style,
  setOnHoverData,
  isVerticallyStyled,
  activateStep,
  isActive
}) => {
  const stepTextRef = useRef();
  const [textWidth, setTextWidth] = useState();
  const [isHovering, setIsHovering] = useState();

  useEffect(() => {
    const { clientWidth } = stepTextRef.current;
    setTextWidth(clientWidth);
  }, [isVerticallyStyled]);

  const onHover = (bool, e) => {
    if (e)
      setOnHoverData({
        mousePos: [e.clientX, e.clientY],
        hoverAnimationTrigger: bool
      });
    setIsHovering(bool);
  };

  return (
    <button
      className={cn('step', {
        '-hover-off': isHovering === false,
        '-hover-on': isHovering,
        '-big': !isVerticallyStyled,
        '-is-active': isActive,
        '-light-text': index === 3
      })}
      style={style}
      onMouseEnter={e => onHover(true, e)}
      onMouseLeave={e => onHover(false, e)}
      onClick={() => activateStep(index)}
    >
      <div
        className="step-circle"
        style={{
          width:
            isActive || (isHovering && isVerticallyStyled)
              ? `calc(${textWidth}px + 4rem)`
              : null,
          ...backgrounds(index)
        }}
      >
        <Icon className="step-number" name={`np-${index + 1}`} fill />
        <span className="step-text" ref={stepTextRef}>
          {text}
        </span>
      </div>
    </button>
  );
};

Step.propTypes = {
  text: PropTypes.string,
  index: PropTypes.number,
  style: PropTypes.shape(typeof Object),
  setOnHoverData: PropTypes.func,
  isVerticallyStyled: PropTypes.bool,
  activateStep: PropTypes.func,
  isActive: PropTypes.bool
};

Step.propTypesMeta = 'exclude';

export default Step;
