import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import propTypeTheme from 'utils/prop-type-theme';

import cn from 'classnames';

const themes = {
  filter: '-filter-color',
  menu: '-menu-color'
};

const BackgroundHoverEffect = ({ children, parentToCover, enable, theme }) => {
  const [style, setStyle] = useState({});

  useEffect(() => {
    if (parentToCover) {
      const height = parentToCover.offsetHeight;
      const paddingBottom = Number(
        window
          .getComputedStyle(parentToCover)
          .getPropertyValue('padding-bottom')
          .slice(0, -2) //remove "px"
      );
      const borderBottom = 1;
      const borderTop = 1;

      setStyle({
        height: height + borderTop,
        marginTop: `${paddingBottom + borderBottom}px`
      });
    }
  }, [parentToCover]);
  return (
    <React.Fragment>
      <div className="background-hover-effect__trigger">{children}</div>
      {enable && (
        <div
          className={cn('background-hover-effect', theme)}
          style={style}
        ></div>
      )}
    </React.Fragment>
  );
};

BackgroundHoverEffect.defaultProps = {
  enable: true
};

BackgroundHoverEffect.themes = themes;

BackgroundHoverEffect.propTypes = {
  children: PropTypes.node,
  parentToCover: PropTypes.oneOf([PropTypes.node, PropTypes.object]),
  enable: PropTypes.bool,
  theme: PropTypes.oneOf(Object.values(themes))
};

BackgroundHoverEffect.propTypesMeta = 'exclude';
export default BackgroundHoverEffect;
