import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import ContentContainer from 'components/content-container';
import SimpleHeader from 'components/header/simple-header';

const NewsletterAdministrationLayout = ({ children, className, header }) => (
  <div className={cn('newsletter-administration-layout', className)}>
    {header && <SimpleHeader {...header} />}
    <ContentContainer theme={ContentContainer.themes.narrow}>
      {children}
    </ContentContainer>
  </div>
);

NewsletterAdministrationLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  header: PropTypes.exact(SimpleHeader.propTypes)
};

NewsletterAdministrationLayout.propTypesMeta = 'exclude';

export default NewsletterAdministrationLayout;
