import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/link';
import Heading from 'components/heading';
import LinkList from 'components/link-list';

const NestedLink = ({ item, iconData, linkTheme }) =>
  item.items ? (
    <li className="nested-link--list">
      {item.title && (
        <Heading className="nested-link--title">{item.title}</Heading>
      )}
      <LinkList
        items={item.items}
        linkTheme={[
          Link.themes.actionLink,
          !item.isSmall && Link.themes.large
        ].filter(theme => !!theme)}
        theme={LinkList.themes.portfolio}
        withIcon
      />
    </li>
  ) : (
    <li className="nested-link--link">
      <Link theme={linkTheme} {...iconData} {...item} />
    </li>
  );

NestedLink.propTypes = {
  item: PropTypes.shape({
    icon: PropTypes.string,
    id: PropTypes.string,
    onPageEditing: PropTypes.string,
    text: PropTypes.string,
    url: PropTypes.string,
    label: PropTypes.string,
    title: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape(Link.propTypes)),
    isSmall: PropTypes.bool
  }),
  linkTheme: Link.propTypes.theme,
  iconData: PropTypes.shape(typeof Object)
};

LinkList.propTypesMeta = 'exclude';
export default NestedLink;
