import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Button from 'components-old/button';
import Image from 'components-old/image';
import Link from 'components-old/link';
import LinkList from 'components-old/link-list';
import RichText from 'components-old/rich-text';

import propTypeTheme from 'js/utils/prop-type-theme';
import Heading from 'components-old/heading';
import Hyphenate from 'components-old/hyphenate/hyphenate';

const themes = {
  blue: '-theme-blue',
  darkBlue: '-theme-dark-blue',
  orange: '-theme-orange'
};

const InfoBlock = ({
  cta,
  editorTheme,
  icon,
  linkList,
  onPageEditing,
  title,
  text,
  theme,
  url
}) => {
  const titleElement = url ? (
    <Link
      iconBeforeChildren="small-arrow-right"
      iconClassName="info-block--title-icon"
      url={url}
    >
      <Hyphenate text={title} />
    </Link>
  ) : (
    <Hyphenate text={title} />
  );

  // The fallback theme can't be handled with defaultProps because the theme is controlled by both 'theme' and 'editorTheme', which means you could get unexpected combinations of themes.
  const fallbackTheme = !theme && !editorTheme ? themes.blue : '';

  return (
    <div className={cn('info-block', theme, editorTheme, fallbackTheme)}>
      {icon && (
        <Image className="info-block--icon" responsive={false} {...icon} />
      )}
      {title && (
        <Heading onPageEditing={onPageEditing.title}>{titleElement}</Heading>
      )}
      {!url && <div className="info-block--orange-bar" />}
      {text && <RichText className="info-block--text" {...text} />}

      {cta && (
        <Link
          className="info-block--cta-button"
          theme={Button.themes.fill}
          useButtonStyles={true}
          {...cta}
        />
      )}

      {linkList && <LinkList {...linkList} withIcon={true} />}
    </div>
  );
};

InfoBlock.propTypes = {
  cta: PropTypes.exact(Link.propTypes),
  editorTheme: PropTypes.oneOf(Object.values(themes)),
  icon: PropTypes.exact(Image.propTypes),
  linkList: PropTypes.exact(LinkList.propTypes),
  onPageEditing: PropTypes.exact({
    title: PropTypes.string
  }),
  title: PropTypes.string,
  text: PropTypes.exact(RichText.propTypes),
  theme: PropTypes.oneOf(Object.values(themes)),
  url: PropTypes.string
};

InfoBlock.propTypesMeta = 'exclude';

InfoBlock.defaultProps = {
  onPageEditing: {}
};

InfoBlock.themes = themes;

export default InfoBlock;
