import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Heading from 'components-old/heading';

import RichText from 'components-old/rich-text';

const TimelineItem = ({
  isPastDate,
  subTitle,
  text,
  title,
  progress,
  delay,
  isLast
}) => {
  const [startAnimation, setStartAnimation] = useState(false);
  useEffect(() => {
    setTimeout(() => setStartAnimation(true), delay);
  });

  const animTime = progress ? 0.5 * (progress / 100) : 0.5;

  return (
    <div
      className={cn('timeline-item', {
        '-is-active': isPastDate,
        '-has-current-date': progress,
        '-is-last': isLast
      })}
    >
      {title ? (
        <Heading level={3} className="timeline-item--title">
          {title}
        </Heading>
      ) : (
        // NOTE: Spacer element. Do not remove without revising the layout when title is missing
        <div className="timeline-item--title" />
      )}
      {isPastDate || progress ? (
        <React.Fragment>
          <div
            className="timeline-item--progress"
            style={
              startAnimation
                ? {
                    width:
                      progress && progress <= 100 ? `${progress}%` : `100%`,
                    transition: `width ${animTime}s linear`,
                    borderBottom: isLast ? `none` : `4px solid #1c445a`
                  }
                : null
            }
          />
        </React.Fragment>
      ) : null}
      <div className="timeline-item--line ballmaker">
        {!!progress && (
          <div
            className="timeline-item--line-gap"
            style={{ left: `calc(${progress}% + 0.5rem)` }}
          />
        )}
      </div>
      {subTitle && (
        <Heading level={title ? 4 : 3} className="timeline-item--sub-title">
          {subTitle}
        </Heading>
      )}
      <RichText className="timeline-item--text" {...text} />
    </div>
  );
};

TimelineItem.propTypes = {
  isPastDate: PropTypes.bool,
  subTitle: PropTypes.string,
  text: PropTypes.exact(RichText.propTypes),
  title: PropTypes.string,
  progress: PropTypes.number,
  isVisible: PropTypes.bool,
  delay: PropTypes.number,
  isLast: PropTypes.bool
};

TimelineItem.propTypesMeta = 'exclude';

export default TimelineItem;
