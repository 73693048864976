import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import useLocalStorageState from 'js/hooks/use-local-storage-state';
import {
  serializeWithTimestamp,
  deserializeWithTimestamp
} from 'js/utils/local-storage';

import Message from 'components-old/message';
import Button from 'components-old/button/button';
import MessageGlobalModal from 'components-old/message-global/message-global-modal';

const MessageGlobal = ({
  id,
  message = {},
  labels = {},
  initiallyOpen = true,
  zIndex = 20,
  style
}) => {
  const [isMultiLined, setIsMultiLined] = useState(false);
  const [isMounted, setIsMounted] = useState(false); // This is to prevent "jumping" on page-load
  const [isMinimized, setIsMinimized] = useLocalStorageState(id, false, {
    serialize: serializeWithTimestamp,
    deserialize: deserializeWithTimestamp
  });
  const [isModalOpen, setIsModalOpen] = useState(initiallyOpen);
  const [disableAnimation, setDisableAnimation] = useState(true);
  const textRef = useRef();

  const toggle = () => {
    setIsMinimized(state => {
      setIsModalOpen(state);
      return !state;
    });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setIsMinimized(true);
    setIsModalOpen(false);
  };

  useEffect(() => {
    const multiLined = textRef.current.offsetHeight > 30;
    setIsMultiLined(multiLined);
    setIsMounted(true);
    if (!multiLined && initiallyOpen) setIsModalOpen(false);

    const animationTimer = setTimeout(() => {
      setDisableAnimation(false);
    }, 500);

    return () => clearTimeout(animationTimer);
  }, []);

  const hideButtonProps = isMinimized
    ? { iconBeforeChildren: 'arrows-right', text: labels.show }
    : { icon: 'arrows-right', text: labels.hide };

  return (
    <div
      className={cn('message-global', { '-hide': !isMounted })}
      onClick={e => e.stopPropagation()} // Prevents openModal-click from triggering close-modal-click on document from useOutsideClick() in MessageGlobalModal
      style={{ zIndex, ...style }}
    >
      <Button
        onClick={openModal}
        className={cn('message-global--message-container')}
        disabled={!isMultiLined || isMinimized}
      >
        <Message
          className={cn('message-global--message', {
            '-one-line-limit': isMultiLined,
            '-hide-text': isModalOpen && isMultiLined,
            '-is-minimized': isMinimized,
            '-disable-animation': disableAnimation
          })}
          {...message}
          ref={textRef}
        />
      </Button>
      {isMounted && (
        <Button
          className={cn('message-global--hide-button', {
            '-is-closed': isMinimized
          })}
          onClick={toggle}
          {...hideButtonProps}
        />
      )}
      {isModalOpen && isMultiLined && (
        <MessageGlobalModal message={message} hideModal={hideModal} />
      )}
    </div>
  );
};

MessageGlobal.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.exact(Message.propTypes),
  labels: PropTypes.exact({
    show: PropTypes.string,
    hide: PropTypes.string
  }),
  initiallyOpen: PropTypes.bool,
  zIndex: PropTypes.number,
  style: PropTypes.shape(typeof Object)
};

MessageGlobal.propTypesMeta = 'exclude';

export default MessageGlobal;
