import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button';
import RichText from 'components/rich-text';
import Link from 'components/link';

import cn from 'classnames';

const RSS = ({ rssHeader, rssInfo, rssButton }) => (
  <React.Fragment>
    <div className={cn('rss-block')}>
      <div className={cn('rss-block__header')}>
        <h2>{rssHeader}</h2>
      </div>
      <div className={cn('rss-block__info')}>
        {rssInfo && <RichText {...rssInfo} />}
      </div>
      <Link
        theme={[Button.themes.primary, Button.themes.big]}
        useButtonStyles={true}
      >
        {rssButton}
      </Link>
    </div>
  </React.Fragment>
);

RSS.propTypes = {
  rssHeader: PropTypes.string,
  rssInfo: PropTypes.exact(RichText.propTypes),
  rssButton: PropTypes.string
};

export default RSS;
