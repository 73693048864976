import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import propTypeTheme from 'utils/prop-type-theme';

const themes = {
  narrow: '-theme-narrow',
  medium: '-theme-medium',
  wide: '-theme-wide'
};

const ContentContainer = ({ children, className, element, theme }) => {
  const Element = element;

  return (
    <Element className={cn('content-container', className, theme)}>
      {children}
    </Element>
  );
};

ContentContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  element: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes))
};

ContentContainer.propTypesMeta = 'exclude';

ContentContainer.defaultProps = {
  element: 'div'
};

ContentContainer.themes = themes;

export default ContentContainer;
