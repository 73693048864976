import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import cn from 'classnames';

import TabTrapper from './tab-trapper';

class Modal extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    id: PropTypes.string,
    isVisible: PropTypes.bool,
    hideModal: PropTypes.func.isRequired // will be triggered by click on close button, modal background or by pressing the esc key
  };

  static propTypesMeta = 'exclude';

  state = {
    bodyElement: null
  };

  componentDidMount() {
    this.setState({ bodyElement: document.body });
    window.addEventListener('keyup', this.handleEscPress);
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.handleEscPress);
  }

  componentDidUpdate(prevProps) {
    if (this.props.isVisible !== prevProps.isVisible && this.props.isVisible) {
      this.onAfterShowModal();
    }
  }

  onAfterShowModal = () => {
    // Wait one frame before focusing
    requestAnimationFrame(() => {
      this.modal.focus();
      this.modalWrapper.scrollTop = 0;
    });
  };

  handleEscPress = e => {
    if (e.which === 27) {
      this.props.hideModal();
    }
  };

  render() {
    const ariaProps = { 'aria-modal': true, role: 'dialog' };

    return !this.state.bodyElement
      ? null
      : ReactDOM.createPortal(
          <div
            className={cn('modal', {
              'is-visible': this.props.isVisible
            })}
            id={this.props.id}
            ref={d => (this.modalWrapper = d)}
            suppressHydrationWarning={true}
            {...ariaProps}
          >
            <div className="modal-background" onClick={this.props.hideModal} />

            <div
              className={cn('modal-content', this.props.className)}
              ref={d => (this.modal = d)}
              tabIndex={-1}
            >
              <TabTrapper isActive={this.props.isVisible}>
                {this.props.children}
              </TabTrapper>
            </div>
          </div>,
          this.state.bodyElement
        );
  }
}

export default Modal;
