import React from 'react';
import PropTypes from 'prop-types';

import propTypeTheme from 'utils/prop-type-theme';

import cn from 'classnames';

import DocumentIcon from 'components-old/document-icon';
import Link from 'components-old/link';

const themes = {
  vertical: '-theme-vertical'
};

const Metadata = ({ items, theme }) => (
  <dl className={cn('metadata', theme)}>
    {items.map(item => (
      <React.Fragment key={item.label + item.text}>
        <dt>
          {item.icon && (
            <div className="metadata--icon">
              <DocumentIcon
                size={DocumentIcon.sizes.xsmall}
                iconTheme={item.icon.iconTheme}
              ></DocumentIcon>
            </div>
          )}
          {`${item.label}:`}
        </dt>
        <dd className={cn({ 'metadata--data--disabled': item.isDisabled })}>
          {item.url ? <Link url={item.url} text={item.text} /> : item.text}
        </dd>
      </React.Fragment>
    ))}
  </dl>
);

Metadata.defaultProps = {
  items: []
};

Metadata.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.exact({
      icon: PropTypes.exact(DocumentIcon.propTypes),
      label: PropTypes.string,
      text: PropTypes.string,
      url: PropTypes.string,
      isDisabled: PropTypes.bool
    })
  ),
  theme: PropTypes.oneOf(Object.values(themes))
};

Metadata.propTypesMeta = 'exclude';

Metadata.themes = themes;

export default Metadata;
