import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import OPE from 'js/on-page-editing';
import useOnIntersect from 'js/hooks/use-on-intersect';
import getNewSrc from 'js/responsive-images';
import propTypeTheme from 'js/utils/prop-type-theme';
import Icon from 'components/icon';

const themes = {
  roundedSmall: '-theme-rounded-small',
  rounded: '-theme-rounded',
  //following themes are used in print
  anchored: '-theme-anchored',
  mosaic: '-theme-mosaic'
};

const FluidImage = ({
  alt,
  className,
  focusPoint,
  initialSize,
  onPageEditing,
  src,
  darkFilter,
  theme
}) => {
  const container = useRef();
  const [currentSrc, setSrc] = useState(getNewSrc(src, initialSize));

  const darkGradient = darkFilter
    ? 'linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),'
    : '';

  useOnIntersect(container, () =>
    setSrc(getNewSrc(src, container.current.offsetWidth, initialSize))
  );

  return (
    <div
      className={cn('fluid-image', className, theme)}
      ref={container}
      {...OPE(onPageEditing === null ? null : onPageEditing.image)}
    >
      {currentSrc && (
        <>
          <div
            className={cn('fluid-image--image')}
            style={{
              backgroundImage: `${darkGradient} url(${currentSrc})`,
              backgroundPosition:
                focusPoint && `${focusPoint.x}% ${focusPoint.y}%`
            }}
          />
          <img src={currentSrc} alt={alt} />
        </>
      )}
    </div>
  );
};

FluidImage.themes = themes;

FluidImage.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  focusPoint: PropTypes.exact({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired
  }),
  initialSize: PropTypes.number,
  onPageEditing: PropTypes.exact({
    image: PropTypes.string
  }),
  src: PropTypes.string,
  darkFilter: PropTypes.bool,
  // eslint-disable-next-line @creuna/prop-types-csharp/all
  theme: PropTypes.oneOfType([
    PropTypes.oneOf(Object.values(themes)),
    PropTypes.arrayOf(PropTypes.oneOf(Object.values(themes)))
  ])
};

FluidImage.propTypesMeta = 'exclude';

FluidImage.defaultProps = {
  initialSize: 200,
  onPageEditing: {}
};

export default FluidImage;
