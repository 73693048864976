import React from 'react';
import PropTypes from 'prop-types';
import ProposalData from '../proposal-page/proposal-data';
import ProposalContact from 'components/proposal-page/proposal-contact';
import Link from 'components/link';
import cn from 'classnames';

const TibiProposalData = ({
  items,
  contacts,
  contactLabel,
  rightColumn = false,
  arbitrary = false
}) => {
  if (rightColumn && !arbitrary) {
    return (
      <>
        {items.map((item, idx) => (
          <div
            style={{ gridRowStart: idx + 1 }}
            className="tibi-proposal-data--right"
            key={item.label}
          >
            <TibiProposalData.Item item={item} idx={idx} />
          </div>
        ))}
        {contacts && (
          <div
            style={{ gridRowStart: items.length + 1 }}
            className="tibi-proposal-data--right"
          >
            <span className="tibi-proposal-data--label">{contactLabel}</span>
            <div
              className={cn('tibi-proposal-data--value', {
                'tibi-proposal-data--value-multiple-contacts':
                  contacts.length > 1
              })}
            >
              {contacts.map(contact => (
                <ProposalContact {...contact} />
              ))}
            </div>
          </div>
        )}
      </>
    );
  } else if (!rightColumn && !arbitrary) {
    return items.map((item, idx) => (
      <div className="tibi-proposal-data--left" key={item.label}>
        <TibiProposalData.Item item={item} />
      </div>
    ));
  }

  if (arbitrary) {
    return items.map((item, idx) => (
      <div
        className={`tibi-proposal-data${idx % 2 == 0 ? '--left' : '--right'}`}
        key={item.label}
      >
        <TibiProposalData.Item item={item} idx={idx} />
      </div>
    ));
  }
};

TibiProposalData.Item = ({ item, idx = 0 }) => (
  <>
    <span className="tibi-proposal-data--label">{item.label}</span>
    <span className="tibi-proposal-data--value">
      {item.text && (
        <span className="tibi-proposal-data--value-text">{item.text}</span>
      )}
      {item.links &&
        item.links.map(link => (
          <a
            className="tibi-proposal-data--link"
            href={link.url}
            key={link.text}
          >
            <span>{link.text}</span>
          </a>
        ))}
    </span>
  </>
);

TibiProposalData.propTypes = {
  rightColumn: PropTypes.bool,
  arbitrary: PropTypes.bool,
  items: PropTypes.arrayOf(ProposalData.propTypes.items),
  contacts: PropTypes.arrayOf(PropTypes.exact(ProposalContact.propTypes)),
  contactLabel: PropTypes.string
};

TibiProposalData.defaultProps = {
  items: [{ links: [] }],
  onPageEditing: {}
};

TibiProposalData.propTypesMeta = 'exclude';

export default TibiProposalData;
