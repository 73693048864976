import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Link from 'components/link';
import NavigationList from './navigation-list';

const NavigationGroup = ({ items, title, className }) => {
  return (
    <div className={cn('navigation-group', className)}>
      {title && <h2>{title}</h2>}
      <ul>
        {items.map(({ link, linkList }, index) => {
          if (link) {
            const linkRef = useRef();
            return (
              <li key={link.url + String(index)} ref={linkRef}>
                <Link
                  {...link}
                  theme={[Link.themes.white, Link.themes.fullWidth]}
                  noHoverEffect
                />
              </li>
            );
          }

          if (linkList) {
            return (
              <li key={linkList.title + String(index)}>
                <NavigationList {...linkList} />
              </li>
            );
          }
          return null;
        })}
      </ul>
    </div>
  );
};

NavigationGroup.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.exact({
      link: PropTypes.exact(Link.propTypes),
      linkList: PropTypes.exact(NavigationList.propTypes)
    })
  ),
  title: PropTypes.string,
  className: PropTypes.string
};

NavigationGroup.propTypesMeta = 'exclude';

NavigationGroup.defaultProps = {
  items: []
};

export default NavigationGroup;
