/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Button from 'components-old/button';
import Icon from 'components-old/icon';

import OPE from 'js/on-page-editing';
import propTypeTheme from 'js/utils/prop-type-theme';
import Hyphenate from 'components-old/hyphenate';

import api from 'js/api-helper';

const themes = {
  black: '-theme-black',
  bold: '-theme-bold',
  fullWidth: '-theme-full-width',
  inlineIcon: '-theme-inline-icon',
  orangeIcon: '-theme-orange-icon',
  small: '-theme-small',
  tag: '-theme-tag',
  orangeTag: '-theme-orange-tag',
  underline: '-theme-underline',
  white: '-theme-white',
  portfolio: '-theme-portfolio'
};

const buttonThemes = {
  big: '-theme-big',
  link: '-theme-link',
  linkPrimary: '-theme-link-primary',
  linkSecondary: '-theme-link-secondary',
  fill: '-theme-fill',
  outline: '-theme-outline',
  orangeOutline: '-theme-orange-outline',
  small: '-theme-small',
  medium: '-theme-medium',
  uppercase: '-theme-uppercase',
  white: '-theme-white',
  smallMargin: 'theme-small-margin'
};

const split = str => {
  if (typeof str === 'string' && str.includes('///'))
    return (
      <span>
        {str.split('///').map(line => (
          <span className="link-line" key={line}>
            {line}
          </span>
        ))}
      </span>
    );
  return <Hyphenate text={str} />;
};
// NOTE: If link receives a Button theme, it inherits styles from the Button component instead of Link
const Link = ({
  attributes,
  children,
  className,
  icon,
  iconBeforeChildren,
  iconClassName,
  id,
  onClick,
  onPageEditing,
  text,
  theme,
  url,
  trackUrl,
  useButtonStyles,
  label,
  noHoverEffect,
  openInNewWindow,
  isExternal,
  noFollow
}) => {
  const [role, setRole] = React.useState(null);

  React.useEffect(() => {
    setRole(onClick ? 'button' : null);
  }, [onClick]);

  const handleOnClick = e => {
    if (trackUrl) {
      var defaultBehaviour = e.ctrlKey;
      if (!defaultBehaviour) {
        e.preventDefault();
      }
      api.execute(trackUrl, {}).then(_response => {
        if (!defaultBehaviour && url) {
          window.location.href = url;
        }
      });
    }
  };

  const Element = url ? 'a' : 'span';
  const baseClassName = useButtonStyles ? Button.className : 'link';

  const externalLinkHelper = () => {
    const TextAndIcon = () => {
      const withoutLastWord =
        text &&
        text
          .split(' ')
          .slice(0, text.split(' ').length - 1)
          .join(' ');
      const lastWord = text ? ` ${text.split(' ').pop()}` : '';

      return (
        <>
          {children}
          {split(withoutLastWord)}
          <span style={{ whiteSpace: 'nowrap' }}>
            {lastWord}
            <Icon name="external-link-arrow" className="external-link-icon" />
          </span>
        </>
      );
    };

    const ExternalLinkContent = () => (
      <span>
        {iconBeforeChildren && (
          <Icon className={iconClassName} name={iconBeforeChildren} />
        )}
        <TextAndIcon />
        {icon && <Icon className={iconClassName} name={icon} />}
      </span>
    );

    return ExternalLinkContent;
  };

  const ExternalLinkContent = isExternal && url && externalLinkHelper();

  const newWindowProps = openInNewWindow
    ? { target: '_blank', rel: 'noopener norefferer' }
    : {};
  return (
    <Element
      className={cn(baseClassName, className, theme, {
        '-has-children': !!text || !!children,
        '-has-icon-left': iconBeforeChildren,
        '-has-icon-right': icon,
        '-has-hover-effect': !noHoverEffect,
        '-is-external-link': !!ExternalLinkContent
      })}
      id={id}
      href={url || null} // The 'null' makes sure that the 'href' attribute is not written to the DOM when 'url' is an empty string
      onClick={onClick || handleOnClick}
      role={role}
      aria-label={label}
      {...newWindowProps}
      {...attributes}
      {...OPE(onPageEditing)}
      rel={noFollow && 'nofollow'}
    >
      {ExternalLinkContent ? (
        <ExternalLinkContent />
      ) : (
        <>
          {iconBeforeChildren && (
            <Icon className={iconClassName} name={iconBeforeChildren} />
          )}
          {children}
          {split(text)}
          {icon && <Icon className={iconClassName} name={icon} />}
        </>
      )}
    </Element>
  );
};

Link.propTypes = {
  attributes: PropTypes.shape(typeof Object),
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.string,
  iconBeforeChildren: PropTypes.string,
  iconClassName: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  onPageEditing: PropTypes.string,
  text: PropTypes.string,
  theme: PropTypes.oneOf(Object.assign({}, buttonThemes, themes)),
  url: PropTypes.string,
  trackUrl: PropTypes.string,
  useButtonStyles: PropTypes.bool,
  label: PropTypes.string,
  noHoverEffect: PropTypes.bool,
  isExternal: PropTypes.bool,
  openInNewWindow: PropTypes.bool,
  isPrimary: PropTypes.bool, // This is only to add it to the CS model
  noFollow: PropTypes.bool
};

Link.propTypesMeta = 'exclude';

Link.themes = themes;

export default Link;
