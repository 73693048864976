import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import OPE from 'js/on-page-editing';
import propTypes from 'prop-types';

const themes = {
  narrow: '-theme-narrow',
  white: '-theme-white'
};

// NOTE: Do not use <React.Fragment> in this component. It breaks rendering to string in IE11 (which is done in map.jsx)
const HtmlString = ({
  className,
  onPageEditing,
  theme,
  text,
  disableTabbing,
  refProp
}) => {
  const htmlStringRef = useRef();

  useEffect(() => {
    if (htmlStringRef.current) {
      // Wrap tables in divs to create horizontal scroll on overflowing tables.
      const tables = htmlStringRef.current.getElementsByTagName('table');

      for (const table of tables) {
        const parent = table.parentNode;
        const nextSibling = table.nextElementSibling;

        const wrapper = document.createElement('div');
        wrapper.classList.add('html-string__table-wrapper');
        wrapper.appendChild(table);

        parent.insertBefore(wrapper, nextSibling);
      }
    }
  }, []);

  useEffect(() => {
    if (!disableTabbing) return;
    // Disable tabbing to links
    const links = htmlStringRef.current.getElementsByTagName('a');
    links.forEach(link => {
      link.tabIndex = -1;
    });
  }, [disableTabbing]);
  return text ? (
    <div
      ref={ref => {
        htmlStringRef.current = ref;
        refProp && (refProp.current = ref);
      }}
      className={cn('html-string', theme, className)}
      dangerouslySetInnerHTML={{
        __html: text
      }}
      {...OPE(onPageEditing && onPageEditing.text)}
    />
  ) : null;
};

HtmlString.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onPageEditing: PropTypes.exact({
    text: PropTypes.string
  }),
  theme: PropTypes.oneOf(Object.values(themes)),
  text: PropTypes.string.isRequired,
  disableTabbing: PropTypes.bool,
  refProp: PropTypes.shape({ current: propTypes.node })
};

HtmlString.propTypesMeta = 'exclude';

HtmlString.defaultProps = {
  onPageEditing: {}
};

HtmlString.themes = themes;

export default HtmlString;
