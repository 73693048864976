import React from 'react';
import PropTypes from 'prop-types';
import RichText from 'components/rich-text';
import ContentWithSidebar from 'components/content-with-sidebar';
import Rss from 'components/rss/rss';
import Message from 'components/message';
import ContentContainer from 'components/content-container';
import Icon from 'components/icon';

const StatusPage = ({
  title,
  serviceStatus,
  statusMessage,
  rssHeader,
  rssInfo,
  onPageEditing,
  rssButton,
  icons
}) => {
  const [isCollapsed, setIsCollapsed] = React.useState({
    site: false,
    skattefunn: false,
    projectBank: false
  });

  const toggleCollapse = section => {
    setIsCollapsed(prevState => ({
      ...prevState,
      [section]: !prevState[section]
    }));
  };

  return (
    <ContentContainer className="status-page" onPageEditing={onPageEditing}>
      <h1>{title}</h1>

      <div className="status-page__mode">
        {icons &&
          icons.map((icon, index) => (
            <div key={index} className="status-page__status">
              {icon.title}
              <Icon
                name={icon.name}
                fill={null}
                className={`icon--${icon.name}`}
              />
            </div>
          ))}
      </div>

      <div className="status-page__message">
        {statusMessage && <Message {...statusMessage} />}
      </div>

      <ContentWithSidebar className="status-page__content">
        <div>
          {serviceStatus.map((status, index) => (
            <div key={index} className="status-page__collapse">
              <div className="status-page__text">
                <h2 onClick={() => toggleCollapse(status.title)}>
                  <Icon
                    name="arrow-down"
                    className={isCollapsed[status.title] ? 'rotate-180' : ''}
                  />
                  {status.title}
                </h2>
                <Icon
                  name={status.icon}
                  fill={null}
                  className={`icon--${status.icon}`}
                />
              </div>
              {isCollapsed[status.title] && (
                <div class="status-page__info">
                  <p onPageEditing={onPageEditing}>{status.description}</p>
                </div>
              )}
            </div>
          ))}
        </div>

        <Rss rssHeader={rssHeader} rssInfo={rssInfo} rssButton={rssButton} />
      </ContentWithSidebar>
    </ContentContainer>
  );
};

StatusPage.propTypes = {
  title: PropTypes.string,
  serviceStatus: PropTypes.arrayOf(
    PropTypes.exact({
      title: PropTypes.string,
      description: PropTypes.string,
      icon: PropTypes.string
    })
  ),
  statusMessage: PropTypes.exact(Message.propTypes),
  rssHeader: PropTypes.string,
  rssInfo: PropTypes.exact(RichText.propTypes),
  rssButton: PropTypes.string,
  icons: PropTypes.arrayOf(
    PropTypes.exact({
      title: PropTypes.string,
      name: PropTypes.string
    })
  ),
  onPageEditing: PropTypes.exact({
    title: PropTypes.string
  })
};

export default StatusPage;
